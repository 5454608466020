export default (state = [], payload) => {
  switch (payload.type) {
    case "UPLOAD_FILE_ITEM":
      return [
        ...state,
        Object.assign(
          {},
          {
            clientevents: [
              {
                event: "UPLOAD_STARTED",
                toUser: "uploading: " + payload.data.originalFilename,
                date: new Date(),
                stage: "Uploading file...",
              },
            ],
            serverevents: [],
            importSettingsConfirmed: false,
            newUpload: true,
          },
          payload.data
        ),
      ];
    case "UPLOAD_FILE_UPDATE_PROGRESS":
    case "CREATE_IMPORT_ENTITY_SUCCESS":

    case "UPLOAD_FILE_READY":
    case "UPLOAD_FILE_FAILED":
    case "IMPORT_LOG_UPDATE":
    case "CONFIRM_MESSAGE":
    case "IMPORT_CLIENTEVENT_UPDATE":
    case "CONFIRM_IMPORT_SETTINGS":
    case "PROJECT_SLIDE_COUNT":
    case "CONFIRM_IMPORT_SETTINGS_SUCCESS":
      return state.map((i) => {
        if (i.projectid !== payload.data.projectid) {
          return i;
        } else return updateProgress(i, payload);
      });
      break;

    case "GET_IMPORT_ENTITY_STATE":
      let found = false;
      for (let s of state)
        if (s.projectid === payload.data.projectid) {
          found = true;
          s.loading = true;
        }
      if (!found) state.push({ projectid: payload.data.projectid, loading: true });
      return state;
      break;

    case "GET_IMPORT_ENTITY_STATE_SUCCESS":
      let foundSuccess = false;
      for (let s of state)
        if (s.projectid === payload.data.projectid) {
          foundSuccess = true;

          s = Object.assign(s, payload.data, { loading: false });
          /* return state.map(i => {
            if (i.projectid !== payload.data.projectid) {
              return i;
            } else return updateProgress(i, payload);
          }); */
        }
      if (!foundSuccess) state.push(payload.data);
      return state;

    case "GET_IMPORT_ENTITY_STATE_FAILURE":
      let foundFalse = false;
      for (let s of state)
        if (s.projectid === payload.data.projectid) {
          foundFalse = true;
          s = Object.assign(s, payload.data, { loading: false });
        }

      return state;
      break;

    default:
      return state;
  }
};

function updateProgress(state, payload) {
  switch (payload.type) {
    case "GET_IMPORT_ENTITY_STATE_SUCCESS":
      state = Object.assign(state, payload.data);
      return state;
    case "CONFIRM_IMPORT_SETTINGS":
      state = Object.assign({}, state, payload.data, { importSettingsConfirmed: true });
      if (!state.hasOwnProperty("clientevents")) state.clientevents = [];
      state.clientevents.push({
        event: "import settings confirmed",
        toUser: "import settings confirmed",
        date: new Date(),
      });
      return state;

    case "PROJECT_SLIDE_COUNT":
      return { ...state, slideCount: payload.data.slideCount };

    case "CONFIRM_IMPORT_SETTINGS_SUCCESS":
      return { ...state, confirmImportSettings: false, confirmImportSettingsSuccess: true };

    case "IMPORT_LOG_UPDATE":
      let found = false;
      if (!state.serverevents) state.serverevents = [];
      for (let ss of state.serverevents) if (ss.messageId === payload.data.messageId) found = true;
      if (!found) state.serverevents.push(payload.data);
      return state;

    case "CONFIRM_MESSAGE":
      if (state.serverevents)
        for (let s of state.serverevents) if (s.messageId === payload.data.messageId) s.confirmed = true;
      return state;

    case "IMPORT_CLIENTEVENT_UPDATE":
      if (state.clientevents) state.clientevents.push(payload.data);
      return state;

    case "UPLOAD_FILE_UPDATE_PROGRESS":
      let uploadProgress = parseInt(payload.data.progress);
      let obj = Object.assign({}, state, {
        uploadProgress: payload.data.progress,
        progress: Math.round((payload.data.progress / 10) * 10) / 10,
        totalSize: payload.data.totalSize,
        completeSize: payload.data.completeSize,
        interval: payload.data.interval,
      });
      if (!state.estimatedTime && obj.totalSize) {
        let estimate = 30 + Math.round(obj.totalSize / 1000000);
        obj.estimatedTime = Math.max(Math.ceil(estimate / 30) / 2, 1).toFixed(1) + " min";
        state.clientevents.push({
          event: "file size: " + Math.round((obj.totalSize / 1000000) * 100) / 100 + " MB",
          toUser: "file size: " + Math.round((obj.totalSize / 1000000) * 100) / 100 + " MB",
          date: new Date(),
        });
      }
      if (!state.uploadSteps) {
        let createSteps = 4;
        if (obj.totalSize > 1000000) createSteps = 10;
        if (obj.totalSize > 3000000) createSteps = 20;
        if (obj.totalSize > 5000000) createSteps = 50;
        obj.uploadSteps = [];
        for (let i = 0; i < createSteps; i++) {
          let step = Math.round((100 / createSteps) * (i + 1));
          obj.uploadSteps.push({ step: step, stepName: step + " %", ready: false });
        }
      } else obj.uploadSteps = state.uploadSteps;

      for (let u of obj.uploadSteps)
        if (uploadProgress >= u.step && !u.ready) {
          state.clientevents.push({
            event: "uploaded: " + u.stepName,
            toUser: "uploaded: " + u.stepName,
            date: new Date(),
            progress: u.step / 10,
            /* estimatedTime: obj.estimatedTime, */
            stage: "Uploading file...",
          });
          u.ready = true;
        }

      return obj;

    case "UPLOAD_FILE_READY":
      /*console.log("ready");
      state.clientevents.push({
        event: "UPLOAD_READY",
        toUser: "file uploaded successfully",
        date: new Date()
      });*/
      state.uploadReady = true;
      return state;

    case "UPLOAD_FILE_FAILED":
      state.clientevents.push({
        event: "UPLOAD_FAILED",
        toUser: "file upload failed",
        type: "error",
        date: new Date(),
      });
      state.uploadFailed = true;

      return state;

    case "CREATE_IMPORT_ENTITY_SUCCESS":
      return { ...state, savedToBackend: true };
    //return Object.assign({}, state, { savedToBackend: true });

    case "UPDATE_IMPORT_ENTITY_SUCCESS":
      return { ...state, savedToBackendImportSettings: true };
    //return Object.assign({}, state, { savedToBackendImportSettings: true });

    default:
      return state;
  }
}
