export default(state={sceneid:null}, payload) => {
	
    // console.log("PAYLOAD---", payload.type)
    switch(payload.type) {
        case 'CLEAR_SELECTED_SCENES':
        case 'GET_WORK_HISTORY':
        case 'WORK_HISTORY':
            return {sceneid:null}
    	case 'SELECT_SCENE_ID':
    		// alert(JSON.stringify(payload.data.scene))
    		if (payload.data && payload.data.scene && payload.data.scene.sceneid){
    			
    			return { sceneid: payload.data.scene.sceneid}
    		}
    		return state;
    		break;
        default:
            return state;
    }
    
}