function updateScenes(state, payload) {
  if (state === undefined) return state;
  switch (payload.type) {
    case "UPDATE_VIDEO_SCENE":
      return state.map((scene) => {
        if (scene.sceneid !== payload.data.sceneid) {
          return scene;
        }
        return {
          ...scene,
          ...payload.data,
        };
      });
    case "UPDATE_VIDEO_SCENE_ATTRIBUTES":
      return state.map((scene) => {
        if (scene.sceneid !== payload.data.sceneid) {
          return scene;
        }
        return {
          ...scene,
          attributes: {
            ...scene.attributes,
            ...payload.data.attributes,
          },
        };
      });
    case "UPDATE_VIDEO_SCENE_URLS":
      return state.map((scene) => {
        if (scene.sceneid !== payload.data.sceneid) {
          return scene;
        }
        return {
          ...scene,
          urls: {
            ...scene.urls,
            ...payload.data.urls,
          },
        };
      });
    default:
      return state;
  }
}

export default (state = {}, payload) => {
  switch (payload.type) {
    /*case "RESET_VIDEO_DATA":
      console.log("clearing the old project data");
      return {}; */
    case "NEW_VIDEO":
      console.log("new video project received from api");
      return payload.data;
    case "UPDATE_VIDEO":
      console.log("video project updated from api");
      return payload.data;

    case "SET_PROJECT_NAME_SUCCESS":
      if (payload.data.projectid === state.projectid) return { ...state, name: payload.data.name };
      else return state;

    case "WORK_HISTORY_UPDATE_STAGE":
      if (payload.data.projectid === state.projectid) {
        let newattributes = {};
        if (payload.data.hasOwnProperty("stage")) newattributes.stage = payload.data.stage;
        if (payload.data.hasOwnProperty("urls")) newattributes.urls = payload.data.urls;

        return Object.assign(state, newattributes);
      } else return state;

    case "UPDATE_PROJECT_ATTRIBUTES":
      if (payload.data.projectid === state.projectid && payload.data.hasOwnProperty("attributes")) {
        let newstate = Object.assign(state);
        newstate.attributes = Object.assign(state.attributes, payload.data.attributes);
        return newstate;
      } else return state;

    case "UPDATE_PREVIEW_SUCCESS":
      if (payload.data.previewUrl) return { ...state, previewImage: payload.data.previewUrl };
      else return state;

    case "UPDATE_VIDEO_PROJECT_URLS":
      if (payload.data.projectid === state.projectid) return { ...state, urls: payload.data.urls };
      else return state;

    case "UPDATE_VIDEO_PROJECT_ATTRIBUTES":
      if (payload.data.projectid === state.projectid) return { ...state, attributes: payload.data.attributes };
      else return state;

    case "UPDATE_VIDEO_PROJECT_ATTRIBUTES_AND_URLS":
      if (payload.data.projectid === state.projectid)
        return { ...state, urls: payload.data.urls, attributes: payload.data.attributes };
      else return state;
    case "SET_REPLACE_AVATAR_SUCCESS":
      if (payload.data.projectid === state.projectid) {
        let newstate = Object.assign(state);
        newstate.attributes = Object.assign(state.attributes, { newAvatarFace: payload.data.newAvatarFace });
        return newstate;
      } else return state;

    case "UPDATE_VIDEO_PROJECT":
      if (payload.data.projectid === state.projectid) return Object.assign(state, payload.data);
      else return state;

    case "RENDER_VIDEO_SUCCESS":
      if (payload.projectid === state.projectid) {
        if (state.attributes) return { ...state, attributes: { ...state.attributes, renderingPercentage: 0 } };
        else return { ...state, attributes: { renderingPercentage: 0 } };
      } else return state;

    case "UPDATE_VIDEO_SCENE_URLS":
    case "UPDATE_VIDEO_SCENE_ATTRIBUTES":
    case "UPDATE_VIDEO_SCENE":
      return { ...state, scenes: updateScenes(state.scenes, payload) };
    default:
      return state;
  }
};
