const startState = {
  activeStage: 1,
  stages: [
    { name: "Extracting presentation" },
    { name: "Checking notes" },
    { name: "Analyzing data" },
    { name: "Applying settings" },
    { name: "Processing media" },
    { name: "Processing charts" },
    { name: "Preparing project" }
  ],
  filename: " ",
  progress: 0,
  messages: []
};

export default (state = startState, payload) => {
  switch (payload.type) {
    case "VIDEO_PROJECT_IMPORT_STATUS":
      state.progress = payload.data.progress;
      if (payload.data.filename) state.filename = payload.data.filename;
      switch (payload.data.stage) {
        case "IMPORTING_POWERPOINT":
          state.activeStage = 0;
          break;
        case "TOO_MANY_SLIDES":
          state.messages.push({ message: payload.data.message, type: payload.data.type });
          break;

        case "ANALYZING_POWERPOINT":
          state.activeStage = 2;
          break;
        case "CHECKING_NOTES":
          state.activeStage = 1;
          break;
        case "APPLYING_SETTINGS":
          state.activeStage = 3;
          break;
        case "PROCESSING_MEDIA":
          state.activeStage = 4;
          break;
        case "WAITING_LIBREOFFICE":
          state.activeStage = 5;
          break;
        case "CREATING_VIDEO":
          state.activeStage = 6;
          break;
      }
      return state;
      break;

    default:
      return state;
  }
};
