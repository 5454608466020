export default (state = "", payload) => {
  let out = { message: "", type: "" };
  switch (payload.type) {
    case "USER_SIGNUP_BY_FORM_SUCCESS":
      out.message = payload.message;
      out.type = "success";
      break;
    case "CLEAR_LOGIN_MSGS":
    case "GENERATE_LOGIN_TOKEN_SUCCESS":
      out = "";
      break;
    case "GENERATE_LOGIN_TOKEN_FAILURE":
      out.message = payload.message;
      out.type = "error";
      break;
    case "USER_FORGOT_PASSWORD_SUCCESS":
      out.message = payload.message;
      out.type = "success";
      break;
    case "USER_FORGOT_PASSWORD_FAILURE":
      out.message = payload.message;
      out.type = "error";
      break;
    case "LOGIN_FAILURE":
      out.message = payload.message;
      out.type = "error";
      break;
    case "SIGNUP_FAILURE":
      out.message = payload.message;
      out.type = "error";
      break;
    default:
      return state;
  }
  return out;
};
