import React, { Component } from "react";
/* CacheBuster component */
//import packageJson from "../../package.json";
//global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
  // code from above snippet goes here
};

export default class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log("Clearing cache and hard reloading...");
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
          });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
      },
    };
  }

  componentDidMount() {
    fetch(`/meta.json?${new Date().getTime()}`, { cache: "no-cache" })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = process.env.REACT_APP_BUILD_NUMBER;
        let shouldForceRefresh = null;
        if (latestVersion !== currentVersion) {
          shouldForceRefresh = true;
          console.log("NEW VERSION", latestVersion);
          console.log("CURRENT VERSION", currentVersion);
        }
        //const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (process.env.REACT_APP_LOCALDEV) {
          console.log(`You are on - ${latestVersion}. No cache refresh needed.`);
          this.setState({ loading: false, isLatestVersion: true });
        } else if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          this.setState({ loading: false, isLatestVersion: true });
        }
      })
      .catch((err) => {
        console.error("can not read meta.json", err);
        this.setState({ loading: false, isLatestVersion: true });
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}
