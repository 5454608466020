import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "../../actions/index";

const stylesheet = {
  isError: {
    position: "fixed",
    height: 40,
    background: "#BF0711",
    color: "white",
    fontSize: "1.4em",
    width: "100%",
    zIndex: 10000,
    textAlign: "center",
    paddingTop: 10,
  },
};

class GlobalErrors extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // this.props.action.test_fire_global_error()
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.global_error != null) {
      setTimeout(() => {
        this.props.action.clear_global_error();
        this.props.action.reloadScene();
      }, 3000);
    }
  }
  render() {
    if (this.props.global_error != null) {
      return (
        <div style={stylesheet.isError}>
          <strong>Error </strong> : {this.props.global_error.msg}
        </div>
      );
    }
    return null;
  }
}

function mapStateToProps(state, prop) {
  return {
    user: state.user,
    global_error: state.global_error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalErrors);
