export default (state = null, payload) => {
  switch (payload.type) {
    case "EDIT_NOTENOUGHCREDIT_FAILED":
      return { msg: "edit failed, you do not have enough credit" };

    case "CLEAR_GLOBAL_ERROR":
      return null;
    default:
      return state;
  }
};
