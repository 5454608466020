import user from "./user";
import app_data from "./app_data";

import edit_ready from "./edit_ready";
import loading from "./loading";
import { combineReducers } from "redux";

import { connectRouter } from "connected-react-router";
import medialibrary from "./medialibrary";
import medialibrary_size from "./medialibrary_size";
import medialibrary_temp_files from "./medialibrary_temp_files";
import config from "./config";
import global_error from "./global_error";
import upload from "./upload";
import imports from "./imports";
import loginmsg from "./loginmsg";
import analytics_queue from "./analytics_queue";

import project_import_status from "./project_import_status";
import video from "./video";
import selected_scene_id from "./selected_scene_id";
import selected_scene from "./selected_scene";
import work_history from "./work_history";
import medialibrary_pixabay from "./medialibrary_pixabay";
import h5p_session from "./h5p_session";
import wdimensions from "./wdimensions";
import placeholder_feedback from "./placeholder_feedback";
import reload_scene from "./reload_scene";
import medialibrary_height from "./medialibrary_height";
import selected_asset from "./selected_asset";
import last_video_updated from "./last_video_updated";

import global_alert_error from "./global_alert_error";
import changing_scene from "./changing_scene";
import editor_processing from "./editor_processing";
import avatar_processing from "./avatar_processing";
import editor_modal_showing from "./editor_modal_showing";
import credits from "./credits";
import collections from "./collections";
import prices from "./prices";
import courses from "./courses";
import views from "./views";
import course_sessions from "./course_sessions";
import cart from "./cart";
import cart_paying from "./cart_paying";
import stripe_portal from "./stripe_portal";
import notifications from "./notifications";
import links_create_link from "./links_create_link";
import user_country from "./user_country";
import stripe_selling from "./stripe_selling";
import facelist from "./facelist";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    config: config,
    credits: credits,
    prices: prices,
    user: user,
    user_country: user_country,
    notifications: notifications,
    cart: cart,
    cart_paying: cart_paying,
    stripe_portal: stripe_portal,
    courses: courses,
    course_sessions: course_sessions,
    app_data: app_data,

    edit_ready: edit_ready,
    loadinglogin: loading,
    loginmsg: loginmsg,
    medialibrary: medialibrary,
    medialibrary_size: medialibrary_size,
    medialibrary_temp_files: medialibrary_temp_files,
    links_create_link: links_create_link,
    global_error: global_error,
    upload: upload,
    work_history: work_history,
    imports: imports,
    analytics_queue: analytics_queue,
    collections: collections,
    project_import_status: project_import_status,
    video: video,
    selected_scene_id: selected_scene_id,
    selected_asset: selected_asset,
    medialibrary_pixabay: medialibrary_pixabay,
    h5p_session: h5p_session,
    wdimensions: wdimensions,
    placeholder_feedback: placeholder_feedback,
    reload_scene: reload_scene,
    medialibrary_height: medialibrary_height,
    selected_scene: selected_scene,

    last_video_updated: last_video_updated,

    global_alert_error: global_alert_error,
    changing_scene: changing_scene,
    editor_processing: editor_processing,
    avatar_processing: avatar_processing,
    editor_modal_showing: editor_modal_showing,
    views: views,
    stripe_selling: stripe_selling,
    facelist: facelist,
  });

export default rootReducer;
