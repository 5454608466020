import React, { useState, useEffect } from "react";

//import { GoogleLogin } from "react-google-login";
import GoogleIcon from "./GoogleIcon";
import { v4 as uuidV4 } from "uuid";

import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../actions";

import { Base64 } from "js-base64";

export default function GoogleLoginButton(props) {
  const handleAuthorize = async (data) => {
    let scope = "email profile openid";
    let stateObj = {
      userid: null,
      clientid: window.clientid,
      purpose: "login",
    };
    let state = Base64.encode(JSON.stringify(stateObj));

    let url =
      "https://accounts.google.com/o/oauth2/v2/auth?scope=" +
      scope +
      "&access_type=offline&prompt=consent&include_granted_scopes=true&response_type=code&state=" +
      state +
      "&redirect_uri=" +
      process.env.REACT_APP_GOOGLE_OAUTH2_URL +
      "&client_id=" +
      process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID;

    let w = 600;
    let h = 600;
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2 / systemZoom + dualScreenTop;

    window.signupwindow = window.open(
      url,
      "google-login",
      `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
    );
  };

  let buttonText = "Log in";
  if (props.buttonText) buttonText = props.buttonText;

  return (
    <div className="mx-2">
      <button
        onClick={handleAuthorize}
        className="px-2"
        style={{
          backgroundColor: "#fff",
          display: "inline-flex",
          alignItems: "center",
          color: "rgba(0, 0, 0, .54)",

          borderRadius: 4,
          border: "1px solid",
          fontSize: 14,
          fontWeight: "500",
        }}
      >
        <GoogleIcon />{" "}
        <span
          style={{
            paddingRight: 10,
            fontWeight: 500,
            paddingLeft: 0,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          {props.buttonText}
        </span>
      </button>
    </div>
  );
}
