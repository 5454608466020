import React, { useState } from "react";

import { annualToMonthly, formatMonthlyPrice } from "./formatters";

export default function ConfirmPlanUpdate(props) {
  const { tier, annualToggle, currentPlan } = props;
  return (
    <React.Fragment>
      {props.open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-full">
              {/*content*/}
              <div className="text-gray-600 border-0 py-4 px-6 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative gap-1 flex flex-row w-auto flex-wrap text-2xl pb-4">Changing Plan</div>

                {currentPlan && (
                  <div className="flex flex-row w-full my-2 gap-6">
                    You current plan is <span className="italic align-left">{currentPlan}</span>
                  </div>
                )}

                <div className="flex flex-row w-full my-2 gap-6">
                  <div className="flex flex-col gap-2">
                    <div className="mr-4 text-gray-800 italic">Changing to </div>
                    <div className="mr-4 text-gray-800 text-xl font-bold">{tier.plan_name}</div>
                    <div className={"text-black-400 opacity-90 text-2xl"}>
                      {annualToggle ? (
                        `${tier.currencySymbol} ${tier.price_annual} / year`
                      ) : (
                        <div>
                          {tier.currencySymbol}
                          {formatMonthlyPrice(tier.price_monthly)}{" "}
                          <span className="text-gray-500 text-xl">{" /mo"}</span>
                        </div>
                      )}
                    </div>
                    {annualToggle && (
                      <div>
                        (around {tier.currencySymbol} {annualToMonthly(tier.price_annual)} {" / month"})
                      </div>
                    )}
                  </div>
                </div>

                {/*footer*/}
                <div className="flex flex-row w-full justify-self-end self-end ">
                  <div className="flex items-center justify-end pt-1 mt-2 mr-4 ">
                    <button
                      className="text-red background-transparent font-bold uppercase  pt-1 text-sm text-gray-700 outline-none focus:outline-none  mb-1"
                      type="button"
                      style={{ transition: "all .15s ease" }}
                      onClick={() => props.handleCancel()}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="flex items-center justify-end pt-1 mt-2 ">
                    <button
                      className="text-blue background-transparent font-bold uppercase  pt-1 text-sm text-gray-700 outline-none focus:outline-none  mb-1"
                      type="button"
                      style={{ transition: "all .15s ease" }}
                      onClick={() => props.handleConfirm()}
                    >
                      Confirm change
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </React.Fragment>
  );
}
