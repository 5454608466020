export default (state = { data: [] }, payload) => {
  switch (payload.type) {
    case "GET_COURSES_LIST_SUCCESS":
      let out = {};
      out.data = payload.data;
      return out;
    default:
      return state;
  }
};
