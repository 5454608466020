const config = require("../config");

import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useDispatch, useSelector } from "react-redux";

import * as Actions from "../actions";

import DTButton from "../common/theme/DTButton";
import { useEffect } from "react";

export default function UserProfileSettingsDialog(props) {
  const [copySuccess, setCopySuccess] = useState("");
  const user = useSelector((state) => state.user);
  const [apikey, setApikey] = useState("");

  useEffect(() => {
    if (user && user.apikey) setApikey(user.apikey);
  }, [user]);

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      {props.open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-full">
              {/*content*/}
              <div className="text-gray-600 border-0 py-4 px-6 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative gap-1 flex flex-row w-auto flex-wrap text-2xl pb-4">Profile settings</div>

                <div className="flex flex-row w-full my-2">
                  <div className="mr-4 text-gray-800 font-bold">Apikey</div>
                  <div>
                    <input
                      type="text"
                      value={apikey}
                      className="w-72 h-6 pb-2 px-4 py-2 mr-6 border rounded-md shadow-sm border-gray-600 cursor-pointer text-sm text-gray-500 overflow-hidden overflow-ellipsis"
                      onClick={() => {
                        navigator.clipboard.writeText(user.apikey);
                        setCopySuccess("Link copied to clipboard!");
                      }}
                    />
                    <div className="flex flex-row w-full">{copySuccess}</div>
                  </div>

                  <div
                    className="cursor-pointer text-gray-700 text-sm mr-4"
                    onClick={() => {
                      navigator.clipboard.writeText(user.apikey);
                      setCopySuccess("Link copied to clipboard!");
                    }}
                  >
                    COPY
                  </div>
                  <div
                    className="cursor-pointer text-gray-700 text-sm text-center"
                    onClick={() => {
                      setApikey("Creating apikey...");
                      dispatch(Actions.userCreateNewApikey({}));
                    }}
                  >
                    CREATE
                    <br /> NEW APIKEY
                  </div>
                </div>

                {/*footer*/}
                <div className="flex items-center justify-end pt-1 mt-2 ">
                  <button
                    className="text-blue background-transparent font-bold uppercase  pt-1 text-sm text-gray-700 outline-none focus:outline-none  mb-1"
                    type="button"
                    style={{ transition: "all .15s ease" }}
                    onClick={() => props.handleClose()}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </React.Fragment>
  );
}
