function tiers() {
  return [
    /*
    {
      plan_name: "Business",
      price: "30",
      quota: ["2000 slides per month*"],
      description: [
        "Automatic animation",
        "Automatic speech",
        "Music soundtrack",
        "Cartoon Talk",
        "Interactive videos",
        "Track viewer progress",
        "Real-time results",
        "Unlimited hosting**",
      ],
      notes: ["*more slides available with one-time purchases", "**with active subscription"],
  
      buttonVariant: "contained",
    }, */
    {
      plan_name: "Free",
      quota: ["50 slides per month"],
      highlightBox: [
        { t: "Try AI Virtual presenter here !!", bold: true },
        { t: "30 sec video credits", bold: true },
        { t: "per month", bold: true },
      ],
      descriptionTitle: "For all 50 slides:",
      description: [
        { t: "Automatic animation" },
        { t: "Automatic speech" },
        { t: "Music soundtrack" },
        { t: "Cartoon Talk" },
        { t: "Automate.video branding (logo)", bold: true },
        { t: "5 minutes limit per slide", bold: true },
      ],
      notes: [
        /*"*max 5 slides per upload,", "more slides available with one-time purchases" */
      ],

      buttonVariant: "outlined",
      price_monthly: 0,
      price_annual: 0,
    },

    {
      plan_name: "Basic",

      quota: ["100 slides per month"],
      highlightBox: [
        { t: "AI Virtual presenter", bold: true },
        { t: "2 min video credits", bold: true },
        { t: "per month", bold: true },
      ],
      descriptionTitle: "For all 100 slides:",
      description: [
        { t: "Automatic animation" },
        { t: "Automatic speech" },
        { t: "Music soundtrack" },
        { t: "Cartoon Talk" },
        { t: "Interactive videos" },
        { t: "Track viewer progress" },
        { t: "Real-time results" },
        { t: "Download video (mkv, mp4)" },
        { t: "YouTube upload" },
        { t: "Unlimited hosting**" },
      ],
      notes: ["**with active subscription"],

      buttonVariant: "contained",
    },
    {
      plan_name: "Professional",
      subheader: "Most popular",
      quota: ["1000 slides per month"],
      highlightBox: [
        { t: "AI Virtual presenter", bold: true },
        { t: "60 min video credits", bold: true },
        { t: "per month", bold: true },
        { t: "💥New! create Virtual Presenter from your picture", bold: true },
        { t: "💥New! Premium voices" },
        { t: "💥New! Clone your voice" },
      ],
      descriptionTitle: "For all 1000 slides:",
      description: [
        { t: "Automatic animation" },
        { t: "Automatic speech" },
        { t: "Music soundtrack" },
        { t: "Cartoon Talk" },
        { t: "Interactive videos" },
        { t: "Track viewer progress" },
        { t: "Real-time results" },
        { t: "Download video (mkv, mp4)" },
        { t: "YouTube upload" },
        { t: "Unlimited hosting**" },
      ],
      notes: ["**with active subscription"],

      buttonVariant: "contained",
    },
    {
      plan_name: "Business",

      quota: ["10000 slides per month"],
      highlightBox: [
        { t: "AI Virtual presenter", bold: true },
        { t: "180 min video credits", bold: true },
        { t: "per month", bold: true },
        { t: "💥New! create Virtual Presenter from your picture", bold: true },
        { t: "💥New! Premium voices" },
        { t: "💥New! Clone your voice" },
      ],
      descriptionTitle: "For all 10000 slides:",
      description: [
        { t: "Automatic animation" },
        { t: "Automatic speech" },
        { t: "Music soundtrack" },
        { t: "Cartoon Talk" },
        { t: "Interactive videos" },
        { t: "Track viewer progress" },
        { t: "Real-time results" },
        { t: "Download video (mkv, mp4)" },
        { t: "YouTube upload" },
        { t: "Unlimited hosting**" },
        { t: "Priority processing", bold: true },
      ],
      notes: ["**with active subscription"],

      buttonVariant: "contained",
    },
    {
      plan_name: "Enterprise",

      quota: ["50000 slides per month"],
      highlightBox: [
        { t: "AI Virtual presenter", bold: true },
        { t: "1200 min video credits", bold: true },
        { t: "per month", bold: true },
        { t: "💥New! create Virtual Presenter from your picture", bold: true },
      ],
      descriptionTitle: "For all 50000 slides:",
      description: [
        { t: "💥New! Premium voices" },
        { t: "💥New! Clone your voice" },
        { t: "Automatic animation" },
        { t: "Automatic speech" },
        { t: "Music soundtrack" },
        { t: "Cartoon Talk" },
        { t: "Interactive videos" },
        { t: "Track viewer progress" },
        { t: "Real-time results" },
        { t: "Download video (mkv, mp4)" },
        { t: "YouTube upload" },
        { t: "Unlimited hosting**" },
        { t: "Priority processing", bold: true },
      ],
      notes: ["**with active subscription"],

      buttonVariant: "contained",
    },
  ];
}

module.exports = tiers;
