import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import classNames from "classnames";
import Tooltip from "@material-ui/core/Tooltip";

export const styles = (theme) => ({
  root: {
    paddingLeft: "0.7em",
    paddingRight: "0.7em",
    paddingTop: "0.3em",
    WebkitPaddingBefore: "0.65em",
    WebkitPaddingAfter: "0.65em",
    paddingBottom: "0.3em",
    borderRadius: "8px",
    fontSize: 16,
  },
  buttonPrimaryText: {
    "&:hover": {
      zIndex: 0,
      opacity: 0.85,
    },
  },
  buttonPrimary: {
    backgroundColor: "#0951F7",
    color: "white",
    "&:hover, &$focusVisible": {
      /*zIndex: 1, */
      opacity: 0.85,
    },
  },
  buttonColor3: {
    backgroundColor: theme.palette.color3.main,
    color: "white",
    border: "1px solid " + theme.palette.color3.main,
  },
  buttonTertiary: {
    backgroundColor: "#EE6B32",
    color: "white",
    borderColor: "#EE6B32",
    "&:hover, &$focusVisible": {
      opacity: 0.85,
    },
  },
  mediaButton: {
    backgroundColor: theme.palette.media.main,
    color: "white",
    border: 0,
    borderRadius: 2,
    paddingRight: "0.3em",
    paddingLeft: "0.3em",
  },
  buttonMediaAlt: {
    backgroundColor: theme.palette.mediaalt.main,
    color: "white",
    border: 0,
    borderRadius: 2,
    paddingRight: "0.3em",
    paddingLeft: "0.3em",
  },
  buttonLarge: {
    paddingLeft: "1.2em",
    paddingRight: "1.2em",
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
    fontSize: "1.4em",
  },
  iconButton: {
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
    border: "none",
  },
  orange: {
    color: "#EE6B32 !important",
  },
  orangeBackground: {
    color: "white",
    backgroundColor: "#EE6B32 !important",
  },
  buttonDisabled: {
    backgroundColor: "lightgray",
    border: "0px",
  },
  focusVisible: {},
  textRoot: {
    fontSize: "0.9rem",
  },
  textWhite: {
    color: "white",
  },
  textWhite2: {
    color: "white",
  },
  textPrimary: {
    color: "#36355b",
  },
});
function DTButton(Props) {
  const { classes } = Props;
  const props = {
    onClick: Props.onClick,
    disabled: Props.disabled,
    style: Props.style,
  };
  const className = classNames(
    classes.root,
    { [classes.buttonPrimary]: Props.primary },
    { [classes.buttonPrimaryText]: Props.primaryText },
    { [classes.buttonLarge]: Props.large },
    { [classes.buttonTertiary]: Props.tertiary },
    { [classes.mediaButton]: Props.media },
    { [classes.buttonDisabled]: Props.disabled },
    { [classes.buttonMediaAlt]: Props.mediaAlt },
    { [classes.buttonColor3]: Props.color3 },
    { [classes.iconButton]: Props.iconButton },
    { [classes.orange]: Props.orange },
    { [classes.orangeBackground]: Props.orangeBackground }
  );
  let typeStyles = {
    color: "secondary",
  };
  if (Props.orange || Props.orangeBackground) typeStyles = {};
  if (Props.primaryText) {
    typeStyles.color = "primary";
  }
  let typoClassName = classNames({
    [classes.textWhite2]: Props.primary,
    [classes.textPrimary]: Props.primaryText,
    [classes.textWhite]: Props.tertiary,
    [classes.textRoot]: Props.buttonSmallText,
  });

  let baseReturn = (
    <ButtonBase {...props} focusVisibleClassName={classes.focusVisible} className={className}>
      <Typography {...typeStyles} className={typoClassName}>
        {Props.children}
      </Typography>
    </ButtonBase>
  );

  if (Props.tooltip) return <Tooltip title={Props.tooltip}>{baseReturn}</Tooltip>;
  return baseReturn;
}
export default withStyles(styles, { name: "DTButton" })(DTButton);
