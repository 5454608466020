//import ReactGA from "react-ga";
import { gtag, gtagRaw } from "./ga4";

const options = {};
/*
const trackPage = (page) => {
  ReactGA.set({
    page,
    ...options,
  });
  ReactGA.pageview(page);
}; */

const gaEvent = (action) => {
  //console.log("GA EVENT SENT for user", userid);
  /*
  if (userid) {
    ReactGA.set({ dimension1: userid });
    ReactGA.set({ userId: userid });
  }

  ReactGA.event({
    category: "app",
    action: action.type,
  });
  gtag("config", process.env.REACT_APP_GA4, {
    user_id: userid,
  }); */

  if (action.type === "LOGIN_SUCCESS") {
    gtagRaw("event", "login", { user_id: action.user.id.toString() });
  } else {
    gtag("event", action.type);
  }
};

let currentPage = "";

async function sendToLogger(action, store) {
  try {
    const username = store.getState().user.username;

    const addToLoggerQueueUrl = store.getState().config.addToLoggerQueueUrl;

    const response = await fetch(addToLoggerQueueUrl, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: username, socketmessage: action }),
    });
  } catch (err) {
    console.error("WARNING: Can not send events to logger", err);
  }
}

export const googleAnalytics = (store) => (next) => (action) => {
  //const user = store.getState().user;
  //console.log("GA EVENT USER ID", user);
  let userid = null;
  //if (user && user.id) userid = user.id;

  let sendAnalytics = true;

  /*
  if (userid && (userid < 23 || userid === 38)) {
    sendAnalytics = false;
    console.log("do not send analytics for specific userids");
  } */

  if (sendAnalytics) {
    if (action !== undefined && action.type === "@@router/LOCATION_CHANGE") {
      const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;

      if (currentPage !== nextPage) {
        currentPage = nextPage;
        //trackPage(nextPage);
      }
    }

    if (action.meta && action.meta.analytics) {
      gaEvent(action);
      //sendToLogger(action, store);
    }
  }

  return next(action);
};
