import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "../../actions/index";
import Alert from "react-s-alert";
/*
const stylesheet = {
	isError:{
		position:'fixed',
		height:40,
		background:'#BF0711',
		color:'white',
		fontSize:'1.4em',
		width:'100%',
		zIndex:10000,
		textAlign:'center',
		paddingTop:10
	}
} */

class GlobalAlertErrors extends React.Component {
  componentDidMount() {
    // this.props.action.test_fire_global_error()
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.global_error != null) {
      Alert.error(newProps.global_error.msg, { timeout: 5000, effect: "slide" });
      setTimeout(() => {
        this.props.action.clear_global_alert_error();
      }, 3000);
    }
  }
  render() {
    return <div></div>;
  }
}

function mapStateToProps(state, prop) {
  return {
    user: state.user,
    global_error: state.global_alert_error
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(Actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GlobalAlertErrors);
