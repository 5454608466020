import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
//import AppBar from "@material-ui/core/AppBar";
//import compose from "recompose/compose";
//import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
//import Button from "@material-ui/core/Button";
//import Menu from "@material-ui/core/Menu";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as Actions from "../actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DTButton from "../common/theme/DTButton";
import { Redirect } from "react-router-dom";
import { isFeatureEnabled } from "../libs/feature";
import Hidden from "@material-ui/core/Hidden";
import CheeseburgerMenu from "cheeseburger-menu";
import MenuIcon from "@material-ui/icons/Menu";
import AppHeaderProfileMenu from "./AppHeaderProfileMenu";
//import delay from "delay";
import { Typography } from "@material-ui/core";

const styles = () => {};

const stylesheet = {
  root: {
    background: "#b3dff5",
  },
  title: {
    fontWeight: "bold",
    letterSpacing: "-1px",
    fontSize: "2.3vw",
    /*fontSize: "2em", */
    width: "auto",
    marginLeft: "0.3em",
    display: "inline-block",
  },
  devWarning: {
    color: "#ff715b",
    fontWeight: "bold",
    fontSize: "0.5em",
    display: "inline",
  },
  userContainer: {
    marginRight: "0.5em",

    marginTop: "0.3em",
  },
  userAvatar: {
    width: 30,
    height: 30,
    background: "lightblue",
    borderRadius: "100%",
    display: "inline-block",
  },
  username: {
    display: "inline-block",
    position: "relative",

    top: -10,
  },
  item: {
    color: "black",
    textDecoration: "none",
    fontSize: "20px",
    marginLeft: "1em",
    marginRight: "0.5em",

    position: "relative",
  },
};

/*
  const typoProps = {
      color: "secondary",
      display: "inline",
      style: { display: "inline" },
    };
*/
function DisplayLink(props) {
  const spanStyle = props.getNavItemStyle(props.to);
  const navStyle = props.getNavLinkItemStyle(props.to);
  let typocolor = null;
  let style = null;
  if (navStyle.color === "black") {
    typocolor = "primary";
  } else {
    typocolor = "secondary";
    style = { color: "black" };
  }

  return (
    <span style={spanStyle} onClick={props.onClick}>
      <Link style={navStyle} to={props.to}>
        <Typography {...props.typoProps} color={typocolor} style={style}>
          {props.children}
        </Typography>
      </Link>
    </span>
  );
}

class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectUrl: "",
      menuOpen: false,
      dialogOfferCreditsOpen: false,
    };
    this.newProject = this.newProject.bind(this);
    this.getNavItemStyle = this.getNavItemStyle.bind(this);
    this.getNavLinkItemStyle = this.getNavLinkItemStyle.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
  }
  getUserContainer() {
    // return null;
    const typoProps = {
      color: "secondary",
      display: "inline",
    };

    if (this.props.user && this.props.user.id) {
      let credits = 0;
      let plan_name = "";
      let quota_usage = 0;
      let synthesia_usage = 0;
      let monthly_quota = 10;
      let monthly_synthesia = 0;
      let cancel_at = null;

      if (this.props.user.attributes) {
        if (this.props.user.attributes.credits) credits = this.props.user.attributes.credits;
        if (this.props.user.attributes.quota_usage) quota_usage = this.props.user.attributes.quota_usage;
        if (this.props.user.attributes.synthesia_usage) synthesia_usage = this.props.user.attributes.synthesia_usage;
        if (this.props.user.attributes.plan_name) plan_name = this.props.user.attributes.plan_name;
        if (this.props.user.attributes.plan_cancel_at) cancel_at = this.props.user.attributes.plan_cancel_at;
        if (this.props.user.attributes.limits) {
          if (this.props.user.attributes.limits.monthly_quota)
            monthly_quota = this.props.user.attributes.limits.monthly_quota;
          if (this.props.user.attributes.limits.monthly_synthesia)
            monthly_synthesia = this.props.user.attributes.limits.monthly_synthesia;
        }
      }

      return (
        <Grid item>
          <div style={stylesheet.userContainer}>
            <Grid container wrap="nowrap">
              <Hidden xsDown>
                <Grid item style={{ marginTop: "1.1em" }}>
                  <div style={stylesheet.username}>
                    <Typography style={{ color: "black", display: "inline" }} {...typoProps}>
                      {this.props.user.username}
                    </Typography>
                  </div>
                </Grid>
              </Hidden>
              <Grid item style={{ marginTop: "0em" }}>
                <AppHeaderProfileMenu
                  history={this.props.history}
                  plan_name={plan_name}
                  cancel_at={cancel_at}
                  credits={credits}
                  username={this.props.user.username}
                  quota_usage={quota_usage}
                  synthesia_usage={synthesia_usage}
                  monthly_quota={monthly_quota}
                  monthly_synthesia={monthly_synthesia}
                  logout={() => {
                    this.logout({ accessToken: this.props.user?.accessToken });
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      );
    }
  }
  logout(data) {
    // alert("Logout ")
    this.props.action.logout(data);
  }
  newProject() {
    // redirectUrl
    // window.location = "/#!/uploads"
    this.setState({ redirectUrl: "/uploads" }, () => {
      this.setState({ redirectUrl: "" });
    });
  }
  getNavItemStyle(itemName) {
    let spanStyle = {
      padding: "30px",
      // background:'red',
      position: "relative",
    };

    if (this.props.wdimensions && this.props.wdimensions.type) {
      if (this.props.wdimensions.type === "small") {
        spanStyle.padding = 5;
      } else if (this.props.wdimensions.type === "medium") {
        spanStyle.padding = 5;
      } else {
        spanStyle.padding = 5;
        /*
        if (itemName === this.props.rt.pathname) {
          spanStyle.background = "rgb(244, 248, 255)";
          spanStyle.color = "black";
        }*/
      }
    }
    return spanStyle;
  }
  getNavLinkItemStyle(itemName) {
    let secondaryStyle = {
      paddingLeft: "0.5em",
      paddingRight: "0.5em",
      marginTop: 4,
      position: "relative",
      top: 11,
      color: "white",
      textDecoration: "none",
    };

    if (this.props.wdimensions && this.props.wdimensions.type) {
      if (this.props.wdimensions.type === "small") {
        secondaryStyle.paddingLeft = "0.1em";
        secondaryStyle.paddingRight = "0.1em";
        secondaryStyle.fontSize = "0.85em";
        if (itemName === this.props.rt.pathname) {
          secondaryStyle.borderBottom = "1px solid white";
        }
      } else if (this.props.wdimensions.type === "medium") {
        if (itemName === this.props.rt.pathname) {
          secondaryStyle.borderBottom = "1px solid white";
        }
      } else {
        if (itemName === this.props.rt.pathname) {
          secondaryStyle.borderBottom = "1px solid white";
        }
        /*if (itemName === this.props.rt.pathname) {
          secondaryStyle.color = "black";
        }*/
      }
    }
    return secondaryStyle;
  }
  getloggedinmenu() {
    let newProjectStyle = {
      marginLeft: "1em",
      marginRight: "0.5em",
      whiteSpace: "nowrap",
    };
    if (this.props.wdimensions && this.props.wdimensions.type) {
      if (this.props.wdimensions.type === "small") {
        newProjectStyle.fontSize = "0.85em";
      }
    }
    const typoProps = {
      color: "secondary",
      display: "inline",
      style: { display: "inline" },
    };
    const linkProps = {
      getNavItemStyle: this.getNavItemStyle,
      getNavLinkItemStyle: this.getNavLinkItemStyle,
      typoProps: typoProps,
    };
    if (this.props.user && this.props.user.id)
      return (
        <Hidden mdDown>
          <Grid item container direction="row" wrap="nowrap">
            <Grid item>
              <DTButton style={newProjectStyle} tertiary onClick={this.newProject}>
                New Project
              </DTButton>
            </Grid>
            <Grid item container direction="row">
              <Grid item>
                <DisplayLink {...linkProps} to="/myprojects">
                  Projects
                </DisplayLink>
              </Grid>

              {isFeatureEnabled("courses") ? (
                <Grid item>
                  <DisplayLink {...linkProps} to="/myinteractivevideos">
                    Interactive Videos
                  </DisplayLink>
                </Grid>
              ) : (
                ""
              )}
              <Grid item>
                <DisplayLink {...linkProps} to="/myvideos">
                  Videos
                </DisplayLink>
              </Grid>
              {this.props?.user?.attributes?.features?.collections ? (
                <Grid item>
                  <DisplayLink {...linkProps} to="/mycollections">
                    Collections
                  </DisplayLink>
                </Grid>
              ) : null}
              <Grid item>
                <DisplayLink {...linkProps} to="/queue">
                  Queue
                </DisplayLink>
              </Grid>
              <Grid item>
                <DisplayLink {...linkProps} to="/pricing">
                  Upgrade
                </DisplayLink>
              </Grid>
              <Grid item style={{ marginTop: "0.6em", marginRight: "0.6em" }}>
                <span style={{ fontSize: "24px" }}>
                  <a href="https://www.automate.video/tutorials" target="_blank">
                    <i className="fas fa-question-circle text-blue-dark"></i>
                  </a>
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>
      );
  }
  getRedirects() {
    if (this.state.redirectUrl.length > 0) {
      return <Redirect to={this.state.redirectUrl} />;
    }
    return null;
  }
  openMenu() {
    this.setState({ menuOpen: true });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  getBurgerMenu() {
    if (this.props.user && this.props.user.id) {
      return (
        <div>
          <CheeseburgerMenu isOpen={this.state.menuOpen} closeCallback={this.closeMenu.bind(this)}>
            <Grid container direction="column">
              <Grid item style={{ marginBottom: "10px" }}>
                <DTButton
                  style={{
                    marginLeft: "1em",
                    marginRight: "0.5em",
                    marginTop: "0.5em",
                  }}
                  tertiary
                >
                  <Link
                    style={{
                      color: "white",
                      textDecoration: "none",
                    }}
                    to="/uploads"
                    onClick={this.closeMenu}
                  >
                    New Project
                  </Link>
                </DTButton>
              </Grid>
              <Grid item style={{ marginBottom: "10px" }}>
                <span style={stylesheet.item}>
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      paddingBottom: "1em",
                    }}
                    to="/myprojects"
                    onClick={this.closeMenu}
                  >
                    Projects
                  </Link>
                </span>
              </Grid>

              {isFeatureEnabled("courses") ? (
                <Grid item style={{ marginBottom: "10px" }}>
                  <span style={stylesheet.item}>
                    <Link
                      style={{
                        color: "black",
                        textDecoration: "none",
                      }}
                      to="/myinteractivevideos"
                      onClick={this.closeMenu}
                    >
                      Interactive Videos
                    </Link>
                  </span>
                </Grid>
              ) : (
                ""
              )}

              <Grid item style={{ marginBottom: "10px" }}>
                <span style={stylesheet.item}>
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                    }}
                    to="/myvideos"
                    onClick={this.closeMenu}
                  >
                    Videos
                  </Link>
                </span>
              </Grid>
              {this.props?.user?.attributes?.features?.collections ? (
                <Grid item style={{ marginBottom: "10px" }}>
                  <span style={stylesheet.item}>
                    <Link
                      style={{
                        color: "black",
                        textDecoration: "none",
                      }}
                      to="/mycollections"
                      onClick={this.closeMenu}
                    >
                      Collections
                    </Link>
                  </span>
                </Grid>
              ) : null}
              <Grid item style={{ marginBottom: "10px" }}>
                <span style={stylesheet.item}>
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                    }}
                    to="/queue"
                    onClick={this.closeMenu}
                  >
                    Queue
                  </Link>
                </span>
              </Grid>
              <Grid item style={{ marginBottom: "10px" }}>
                <span style={stylesheet.item}>
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                    }}
                    to="/pricing"
                    onClick={this.closeMenu}
                  >
                    Upgrade
                  </Link>
                </span>
              </Grid>

              <Grid item style={{ marginBottom: "10px" }}>
                <span style={stylesheet.item}>
                  <a
                    href="https://www.automate.video/tutorials"
                    style={{
                      color: "black",
                      textDecoration: "none",
                    }}
                    target="_blank"
                    onClick={this.closeMenu}
                  >
                    Tutorials
                  </a>
                </span>
              </Grid>
              <Grid item style={{ marginBottom: "10px" }}>
                <span style={stylesheet.item}>
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                    }}
                    to=""
                    onClick={() => {
                      this.logout({ accessToken: this.props.user?.accessToken });
                    }}
                  >
                    Logout
                  </Link>
                </span>
              </Grid>
            </Grid>
          </CheeseburgerMenu>

          <MenuIcon onClick={this.openMenu.bind(this)} />
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  render() {
    return (
      <div style={stylesheet.root}>
        {this.getRedirects()}
        <div className="flex flex-row space-between py-2">
          <div className="flex flex-row w-full">
            <div className=" xl:hidden self-center ml-6">{this.getBurgerMenu()}</div>
            <a className=" ml-8 align-top flex flex-col justify-start" href={"/"}>
              <img
                className=""
                id="logo"
                src="/assets/Automate-video-logo-with-symbol.png"
                style={{
                  maxHeight: 50,
                  WebkitTransform: "scale(.85)",
                  transform: "scale(.85)",
                  WebkitTransition: "-webkit-transform .25s ease",
                  transition: "-webkit-transform .25s ease",
                  transition: "transform .25s ease",
                  transition: "transform .25s ease,-webkit-transform .25s ease",
                }}
              />
            </a>

            <div style={stylesheet.devWarning}>{process.env.REACT_APP_NODE_ENV !== "production" ? "dev" : ""}</div>

            {this.getloggedinmenu()}
          </div>
          {this.getUserContainer()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, prop) {
  return {
    user: state.user,
    wdimensions: state.wdimensions,
    edit_ready: state.edit_ready,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(Actions, dispatch),
  };
}
AppHeader = withRouter(AppHeader);
AppHeader = withStyles(styles, { name: "AppHeader" })(AppHeader);
export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
