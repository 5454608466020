import React, { Component, useState, useEffect, Suspense, lazy } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as Actions from "../actions/index";

import { withRouter } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import { createTheme } from "@material-ui/core/styles";

import AppHeader from "./AppHeader";
import AppNotifications from "./AppNotifications";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Home from "./pages/home";

import GlobalErrors from "./errors/globalerrors";
import GlobalAlertErrors from "./errors/alerterrors";
import Login from "./Login";
import TokenLogin from "./TokenLogin";
import Alert from "react-s-alert";
import Pricing from "./pages/pricing";

import grey from "@material-ui/core/colors/grey";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";

import CacheBuster from "./CacheBuster";

import { SnackbarProvider, useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import delay from "delay";

// import Logger from "../logger";

import "normalize.css/normalize.css";

const AppAuthenticated = lazy(() => import("./AppAuthenticated"));

// const logger = new Logger();

const publicIp = require("public-ip");
let ipv4 = null;
(async () => {
  try {
    await delay(1000);
    ipv4 = await publicIp.v4();
  } catch (err) {
    console.warn("couldn't find ip on first try");
    console.log(err);
    await delay(5000);
    try {
      ipv4 = await publicIp.v4();
    } catch (err) {
      //if (logger) logger.error("couldn't find ip on second try");
      console.log("couldn't find ip on second try");
    }
  }
})();

function AppWrapper(props) {
  const [ipv4, setIpv4] = useState(null);

  useEffect(() => {
    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    if (!ipv4) {
      getIp();
    }
  }, []);

  const getIp = async () => {
    try {
      console.log("LOOKING FOR IP");
      await delay(1000);

      let ipv4 = await publicIp.v4();
      setIpv4(ipv4);
    } catch (err) {
      console.log(err);
      await delay(5000);
      try {
        let ipv4 = await publicIp.v4();
        setIpv4(ipv4);
      } catch (err) {
        //if (logger) logger.error("couldn't find ip on second try");
        console.log("couldn't find ip on second try");
      }
    }
  };

  return (
    <div>
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          return (
            <div>
              <SnackbarProvider maxSnack={3}>
                <Switch>
                  <Route exact path="/tokenlogin" component={TokenLogin} />
                  <Route path="/" render={(props) => <AppWithRouter ipv4={ipv4} {...props} />} />
                </Switch>
              </SnackbarProvider>
            </div>
          );
        }}
      </CacheBuster>
    </div>
  );
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.resizeHandler = this.resizeHandler.bind(this);
    // fakeAuth.appShell = this;
  }
  state = {
    // theme: process.env.REACT_APP_THEME,
    height: 500,
  };
  resizeHandler() {
    var obj = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    this.props.action.UpdateWindowDimensions(obj);
    //var h = window.innerHeight;
    // this.setState({height:h})
  }
  componentDidMount() {
    window.addEventListener("resize", this.resizeHandler);

    this.resizeHandler();

    this.props.action.loadConfig();

    const userid = this.props?.user?.id || null;

    // if user not logged in, try login with tokens found from localStorage
    if (!userid) {
      var accessToken = null;
      try {
        accessToken = localStorage.getItem("dt_access_token");
      } catch (err) {
        console.log("localStorage can not be accessed", err);
      }
      if (accessToken) {
        this.setState({ loading: true });
        this.props.action.loginWithAccessToken(accessToken);
      } else {
        console.log("did not find accesstoken, looking for login token");
        var token = null;
        try {
          token = localStorage.getItem("dt_login_token");
        } catch (err) {
          console.log("localStorage can not be accessed", err);
        }
        if (token) {
          this.setState({ loading: true });
          this.props.action.loginWithToken(token);
        } else console.log("did not find logintoken either");
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.user.id) {
      this.setState({ loading: false });
    } else if (newProps.user.logging_in === false) {
      this.setState({ loading: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  getLoader() {
    if (this.props.placeholder_feedback) {
      if (this.props.placeholder_feedback.adding) {
        return (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              background: "rgba(0,0,0,0.5)",
            }}
          >
            Loading
          </div>
        );
      }
    }
    return null;
  }

  render() {
    if (
      this.props.user &&
      ipv4 &&
      this.props.user.info &&
      (!this.props.user.info.lastLoggedIp ||
        this.props.user.info.lastLoggedIp !== ipv4 ||
        !this.props.user.info.preferredCurrencies)
    ) {
      this.props.action.updateUserInfo({ lastLoggedIp: ipv4 });
    }

    let userLoggedIn = false;
    if (this.props.user && this.props.user.id) userLoggedIn = true;

    // let themefile = {}
    const { loading } = this.state;
    const theme = createTheme({
      palette: {
        primary: {
          main: "#36355b",
          contrastText: "#fff",
        },
        tertiary: {
          main: "#ff715b",
          contrastText: "#fff",
        },
        media: {
          main: "#379BFC",
        },
        mediaalt: {
          main: "#FBB7B71A",
        },
        color3: {
          main: "#a39b99",
        },
        textSecondary: {
          main: grey[50],
        },
        secondary: {
          main: grey[50],
        },
      },
      typography: { fontFamily: ["Switzer", "sans-serif"].join(",") },
      shape: "flat",
    });

    return (
      <div>
        <MuiThemeProvider theme={theme}>
          <div className="bg-gray-200 min-h-screen " style={{ backgroundColor: "#f5f5f5" }}>
            <GlobalErrors />
            {userLoggedIn ? <AppHeader rt={this.props.location} /> : ""}
            <div style={{ minHeight: this.state.height, height: "100%" }}>
              <GlobalAlertErrors />
              <AppNotifications />
              <Switch>
                <Route exact path="/" component={Home} />

                <Route exact path="/login" component={Login} />

                <Route exact path="/pricing" render={() => <Pricing ipv4={this.props.ipv4} {...this.props} />} />
                <Route
                  exact
                  path="/secret"
                  render={() => <Pricing campaign={"secret"} ipv4={this.props.ipv4} {...this.props} />}
                />
                <Route
                  exact
                  path="/virtual-presenter-plan"
                  render={() => <Pricing campaign={"virtualpresenterplan"} ipv4={this.props.ipv4} {...this.props} />}
                />

                {loading ? (
                  <CircularProgress />
                ) : (
                  <Suspense fallback={<CircularProgress />}>
                    <AppAuthenticated />
                  </Suspense>
                )}
              </Switch>
            </div>

            <Alert stack={{ limit: 3 }} />
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

function mapStateToProps(state, prop) {
  return {
    user: state.user,
    global_error: state.global_error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(Actions, dispatch),
  };
}

const AppWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
export default AppWrapper;
