import React, { Component } from "react";

export default function BasicButton(props) {
  let imageWidth = 25;
  let style = " bg-blue-dark text-white rounded-md py-1 m-0 hover:bg-blue-dark focus:shadow-outline";
  if (props.normal)
    style =
      " border border-gray-500 bg-blue-dark text-white rounded-md py-3 m-0 font-bold hover:bg-blue-dark focus:shadow-outline";
  if (props.inverted)
    style =
      " border border-gray-500 blue-dark bg-blue-light rounded-md py-2  m-0 font-bold hover:bg-grey focus:shadow-outline";
  if (props.disabled) style += " text-gray-300 blue-gray-100 bg-gray-200 ";
  if (props.light) style = " text-blue py-1 m-0";
  if (props.gray) style = " text-gray-500 py-1 m-0";
  let onclick = null;
  if (props.onClick) onclick = props.onClick;
  if (props.icon) style += " px-1 ";
  if (props.smallerIcon) imageWidth = 20;
  if (props.largerIcon) imageWidth = 40;
  if (props.youtubeIcon) imageWidth = 80;
  else if (props.normal) style += " px-6 ";
  else style += " px-4 ";

  let exactStyle = {
    transition: "all .15s ease",
  };

  if (props.headerStyle) exactStyle.fontSize = 16;

  let disabled = false;
  if (props.disabled) disabled = true;

  return (
    <a href={props.url}>
      <button
        type="button"
        disabled={disabled}
        onClick={onclick}
        style={exactStyle}
        className={style + "   transition duration-500 ease select-none  focus:outline-none "}
      >
        {props.icon ? <img width={imageWidth} src={props.icon} /> : props.name}
      </button>
    </a>
  );
}
