import merge from "deepmerge";
import config from "../config";

export default (state = {}, payload) => {
  switch (payload.type) {
    case "GET_COLLECTIONS_BY_PROJECT":
      return { ...state, collectionprojects: payload.data };
      break;
    case "GET_PROJECTS_BY_COLLECTION":
      return { ...state, projectcollections: payload.data };
      break;
    case "GET_PROJECTS_LIST":
      return { ...state, projectlist: payload.data };
      break;
    case "GET_COLLECTIONS":
      return { ...state, collectionlist: payload.data };
      break;
    case "ERROR_EXISTING_COLLECTION":
      return { ...state, errors: payload.data };
      break;
    default:
      return state;
  }
};
