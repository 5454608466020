function updateFile(state, payload) {
  if (state.savedFilename !== payload.data.savedFilename) return state;
  switch (payload.type) {
    case "UPLOAD_FILE_UPDATE_PROGRESS":
      return Object.assign({}, state, {
        progress: payload.data.progress,
        totalSize: payload.data.totalSize,
        completeSize: payload.data.completeSize,
        interval: payload.data.interval
      });

    case "UPLOAD_FILE_READY":
      console.log("ready");
      return Object.assign({}, state, { uploadReady: true });

    case "UPLOAD_FILE_FAILED":
      clearInterval(state.interval);
      return Object.assign({}, state, { uploadFailed: true, interval: null });

    default:
      return state;
  }
}

export default (state = [], payload) => {
  switch (payload.type) {
    case "UPLOAD_FILE_ITEM":
      return [{ savedFilename: payload.data.savedFilename, name: payload.data.name, uploadReady: false }, ...state];

    case "UPLOAD_FILE_UPDATE_PROGRESS":
    case "UPLOAD_FILE_READY":
    case "UPLOAD_FILE_FAILED":
      return state.map(file => updateFile(file, payload));
    default:
      return state;
  }
};
