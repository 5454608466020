export default(state=false, payload) => {
    switch(payload.type) {
        case 'UPDATE_VIDEO_CHANGED_SCENE':
        case 'UPDATE_VIDEO_CHANGING_SCENE':
            return payload.data; 
        default:
            return state;
    }
}

