export default(state=false, payload) => {
    switch(payload.type) {
        case 'EDITOR_MODAL_STATUS':
        	
            return payload.data; 
        default:
            return state;
    }
}

