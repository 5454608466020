function MLHelper(p = null) {
  // var pr = p;
  function matchAgainst(medialibrary, filename) {
    let l = medialibrary.length;
    for (var i = 0; i < l; i++) {
      if (medialibrary[i].saved_filename === filename) {
        return medialibrary[i];
      }
    }
    return null;
  }

  function findByMediaID(medialibrary, mediaid) {
    let l = medialibrary.length;
    console.log("medialibrary length = ", l);
    console.log("Searching for id = " + mediaid);
    for (var i = 0; i < l; i++) {
      // console.log("id = "+ medialibrary[i].mediaid)
      if (medialibrary[i].mediaid === mediaid) {
        return medialibrary[i];
      }
    }
    return null;
  }

  var api = {
    matchAgainst: matchAgainst,
    findByMediaID: findByMediaID
  };

  return api;
}
export default MLHelper;
