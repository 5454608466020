export default (state = [], payload) => {
  switch (payload.type) {
    case "CREATE_LINK_SUCCESS":
      state.push(payload.data);
      return state;

    default:
      return state;
  }
};
