function updateFile(state, payload) {
  if (state.savedFilename !== payload.data.savedFilename) return state;
  switch (payload.type) {
    case "UPLOAD_FILE_UPDATE_PROGRESS":
      return Object.assign({}, state, {
        progress: payload.data.progress,
        totalSize: payload.data.totalSize,
        completeSize: payload.data.completeSize,
        interval: payload.data.interval
      });

    case "UPLOAD_FILE_READY":
      console.log("ready");
      return Object.assign({}, state, { uploadReady: true });

    case "UPLOAD_FILE_FAILED":
      clearInterval(state.interval);
      return Object.assign({}, state, { uploadFailed: true, interval: null });

    default:
      return state;
  }
}

export default (state = [], payload) => {
  let newState = null;
  //a
  switch (payload.type) {
    case "DOWNLOAD_TO_MEDIALIBRARY_SUCCESS":
      newState = Object.assign([], state);
      payload.data.uploadReady = true;
      payload.data.progress = 100;
      payload.data.name = payload.data.saved_filename;
      newState.push(payload.data);
      return newState;
    // case 'UPLOAD_FILE_READY':
    //     let uploaded = payload.data;
    //     uploaded.saved_filename = uploaded.savedFilename
    //     uploaded.url = '';
    //     uploaded.location = '';
    //     newState = Object.assign([], state)
    //     newState.push(uploaded)
    //     return newState;
    // case 'MEDIA_LIBRARY_DRAWER':
    //     return payload.data;
    case "UPLOAD_FILE_ITEM":
      return [
        {
          savedFilename: payload.data.savedFilename,
          name: payload.data.name,
          uploadReady: false
        },
        ...state
      ];

    case "CLEAR_UPLOADS":
      return [];
    case "UPLOAD_FILE_UPDATE_PROGRESS":
    case "UPLOAD_FILE_READY":
    case "UPLOAD_FILE_FAILED":
      return state.map(file => updateFile(file, payload));
    default:
      return state;
  }
};
