import React, { useState, useEffect, useRef } from "react";

import { SnackbarProvider, useSnackbar } from "notistack";

import { useDispatch, useSelector } from "react-redux";

function AppNotifications() {
  const notifications = useSelector((state) => state.notifications);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (notifications.length > 0) {
      enqueueSnackbar(notifications[notifications.length - 1].message, {
        variant: "success",
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
      console.log("notification queued");
    }
  }, [notifications]);

  return null;
}

export default AppNotifications;
