import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import DTButton from "../common/theme/DTButton";
import Hidden from "@material-ui/core/Hidden";
import { Redirect } from "react-router-dom";

import loginimage from "../images/login.jpg";
import TextField from "@material-ui/core/TextField";
import AppHeader from "./AppHeader";

import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { CircularProgress } from "@material-ui/core";
import Alert from "react-s-alert";

import * as Actions from "../actions";
import config from "../config";

import GoogleLoginButton from "../sharedcomponents/GoogleLoginButton";

import LoginDialogPopup from "./LoginDialogPopup";

import qs from "qs";
import CacheBuster from "./CacheBuster";

// import Button from 'material-ui/Button';

export const styles = (theme) => ({
  lineItemContainer: {
    marginTop: "4em",
  },
  filmStrip: {
    border: "2px solid " + theme.palette.primary.main,
    borderRadius: 3,
    padding: "1em",
  },
  loginDiv: {
    width: "100%",
    height: "100vh",
    background: 'url("' + loginimage + '")',
    backgroundSize: "cover",
  },
  loginDivSmall: {
    width: "100%",
    height: "100%",
  },
  loginFormSection: {
    borderColor: "#542856",
    borderLeft: "1px solid",
    background: "#eeecff",
    width: "100%",
    height: "100vh",
    opacity: "1",
    position: "absolute",
    top: 0,
  },
  loginForm: {
    background: "white",
    position: "relative",
    zIndex: 1000,
    margin: "0 auto",
    width: "60%",
    padding: 15,
    marginTop: "10vh",
    borderColor: "#542856",
    border: "1px solid",
    borderRadius: 5,
  },
  largeDisplaySection: {
    paddingTop: "1.6em",
    padding: "4em",
    color: "rgba(58, 44, 74, 0.9)",
  },
  largeDisplaySectionHeading: {
    fontSize: "3.5rem",
    color: "rgba(58, 44, 74, 0.9)",
  },
  toggleButton: {
    color: "rgba(58, 44, 74, 0.9)",
    fontFamily: theme.typography.fontFamily + " !important",
    fontSize: "1.2em",
    marginRight: 10,
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 5,
    marginBottom: 30,
    cursor: "pointer",
  },
  selected: {
    borderBottom: "2px solid rgba(58, 44, 74, 0.9)",
  },
  socialSharingButtonText: {
    fontFamily: theme.typography.fontFamily + " !important",
    marginLeft: 10,
    paddingTop: "0.5em",
    paddingBottom: "0.5em",
  },
  socialSharingButton: {
    // fontSize:'1.1em',
    padding: "0em",
  },
  socialSharingButtonIcon: {
    fontSize: "2em",
    position: "absolute",
    left: 0,
    paddingLeft: 5,
    // padding:'1em'
  },

  loginFormHeader: {
    position: "absolute",
    top: -19,
  },
  loginSelector: {
    background: "white",
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 3,
    borderColor: "#542856",
    border: "2px solid",
    borderBottom: 0,
    marginRight: 5,
    borderRadius: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: "0.875rem",
  },
  bootstrapRoot: {
    padding: 0,
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    width: "calc(100% - 24px)",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#542856",
      boxShadow: "0 0 0 0.2rem rgba(84,40,86,.25)",
    },
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edited: [],
      username: "",
      password: "",
      password2: "",
      name: "",
      company: "",
      acceptTos: false,
      mode: "register",
      openTOS: false,
      openPrivacyPolicy: false,
      waitingListOff: false,
      googleLoginEnabled: false,
    };
    this.performLogin = this.performLogin.bind(this);
    this.performRegister = this.performRegister.bind(this);
    this.performForgotPassword = this.performForgotPassword.bind(this);
    //this.nextPage = this.nextPage.bind(this);
    this.closeTos = this.closeTos.bind(this);
    this.openTos = this.openTos.bind(this);
    this.closePrivacyPolicy = this.closePrivacyPolicy.bind(this);
    this.openPrivacyPolicy = this.openPrivacyPolicy.bind(this);
  }

  componentDidMount() {
    window.location.href = config.player + "/login";
    // this.loadMicrosoft()
    //this.waitandloadGoogle();
    let waitingList = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).waitinglistoff;
    if (waitingList) this.setState({ waitingListOff: true });

    let mode = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).mode;
    if (mode === "register") this.setState({ mode: "register" });
    if (mode === "login") this.setState({ mode: "login" });
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.loginmsg && newProps.loginmsg.message && newProps.loginmsg.message.length > 0) {
      if (newProps.loginmsg.type === "error") Alert.error("Error : " + newProps.loginmsg.message);
      if (newProps.loginmsg.type === "success") Alert.info(newProps.loginmsg.message);
    }
  }
  UNSAFE_componentWillMount() {
    var userHasLogin = null;
    try {
      userHasLogin = localStorage.getItem("dt_user_has_login");
    } catch (err) {
      console.log("localStorage can not be accessed", err);
    }
    if (userHasLogin) this.setState({ mode: "login" });

    try {
      var token = null;
      token = localStorage.getItem("dt_login_token");
    } catch (err) {
      console.log("localStorage can not be accessed", err);
    }
    // this.setState({loading:true});
    if (token) {
      this.props.action.loginWithToken(token);
    }
  }

  updateFieldValue(fieldname, e) {
    let val = e.target.value;
    this.setState({ [fieldname]: val });
  }

  updateCheckboxValue(fieldname, e) {
    let val = e.target.checked;
    this.setState({ [fieldname]: val });
  }

  performLogin(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (this.state.username.length === 0 || this.state.password.length === 0) {
      Alert.error("Login form must be completely filled before submitting.");
      return;
    }

    let data = {
      username: this.state.username,
      password: this.state.password,
    };
    this.props.action.login(data);
    // this.setState({username:'', password:''})
  }

  performRegister(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (this.state.username.length === 0 || this.state.password.length === 0) {
      Alert.error("Login form must be completely filled before submitting.");
      return;
    }

    if (!validateEmail(this.state.username)) {
      Alert.error("Please check the email address");
      return;
    }

    if (this.state.password2 !== this.state.password) {
      Alert.error("Passwords do not match.");
      return;
    }

    if (this.state.acceptTos !== true) {
      Alert.error("Please accept the Terms of Service.");
      return;
    }

    let data = {
      username: this.state.username,
      password: this.state.password,
      displayName: this.state.name,
      companyName: this.state.company,
      acceptTos: this.state.acceptTos,
      waitingListOff: this.state.waitingListOff,
    };
    this.props.action.register(data);
    // this.setState({username:'', password:''})
  }

  closePrivacyPolicy() {
    this.setState({ openPrivacyPolicy: false });
  }

  openPrivacyPolicy() {
    this.setState({ openPrivacyPolicy: true });
  }

  closeTos() {
    this.setState({ openTOS: false });
  }

  openTos() {
    this.setState({ openTOS: true });
  }

  performForgotPassword(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (this.state.username.length === 0) {
      Alert.error("Login form must be completely filled before submitting.");
      return;
    }
    let data = {
      email: this.state.username,
    };
    this.props.action.forgotPassword(data);
    // this.setState({username:'', password:''})
  }

  getFormFields(data) {
    const { classes } = this.props;
    if (this.props.loadinglogin) {
      return <div>Loading </div>;
    }
    if (this.state.mode === "login") {
      if (this.state.loading) {
        return (
          <div>
            <CircularProgress />
          </div>
        );
      }
      return (
        <div>
          <form id="login" onSubmit={this.performLogin}>
            <TextField
              fullWidth
              defaultValue=""
              value={this.state.username}
              label="Email"
              type="email"
              id="usernameinput1"
              autoComplete="username"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput,
                },
              }}
              style={{ marginBottom: 4 }}
              InputLabelProps={{
                shrink: true,
                className: classes.bootstrapFormLabel,
              }}
              onChange={(e) => {
                this.updateFieldValue("username", e);
              }}
            />
            <TextField
              fullWidth
              defaultValue=""
              label="Password"
              id="passwordinput1"
              type="password"
              autoComplete="current-password"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput,
                },
              }}
              style={{ marginBottom: 4 }}
              InputLabelProps={{
                shrink: true,
                className: classes.bootstrapFormLabel,
              }}
              value={this.state.password}
              onChange={(e) => {
                this.updateFieldValue("password", e);
              }}
            />

            <input style={{ display: "none" }} type="submit" value="Submit" />
            <div>
              <DTButton onClick={this.performLogin} primary style={{ width: "100%", marginTop: 10, padding: 10 }}>
                Login
              </DTButton>
            </div>
          </form>
          <div style={{ paddingTop: "2.3em", fontSize: "0.7em" }}>
            <span
              onClick={() => {
                this.changeMode("forgotPassword");
              }}
              className={classNames({ [classes.toggleButton]: true })}
            >
              Forgot password
            </span>
          </div>
        </div>
      );
    } else if (this.state.mode === "register") {
      if (this.state.loading) {
        return (
          <div>
            <CircularProgress />
          </div>
        );
      }
      return (
        <div>
          <form id="register" onSubmit={this.performRegister}>
            <TextField
              fullWidth
              label="Name"
              id="nameinput1"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput,
                },
              }}
              style={{ marginBottom: 4 }}
              InputLabelProps={{
                shrink: true,
                className: classes.bootstrapFormLabel,
              }}
              value={this.state.name}
              onChange={(e) => {
                this.updateFieldValue("name", e);
              }}
            />
            <TextField
              fullWidth
              defaultValue=""
              label="Company"
              id="companyinput1"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput,
                },
              }}
              style={{ marginBottom: 4 }}
              InputLabelProps={{
                shrink: true,
                className: classes.bootstrapFormLabel,
              }}
              value={this.state.company}
              onChange={(e) => {
                this.updateFieldValue("company", e);
              }}
            />
            <TextField
              fullWidth
              defaultValue=""
              label="Email"
              type="email"
              id="usernameinput1"
              autoComplete="username"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput,
                },
              }}
              style={{ marginBottom: 4 }}
              InputLabelProps={{
                shrink: true,
                className: classes.bootstrapFormLabel,
              }}
              value={this.state.username}
              onChange={(e) => {
                this.updateFieldValue("username", e);
              }}
            />
            <TextField
              fullWidth
              defaultValue=""
              label="Password"
              type="password"
              id="passwordinput1"
              autoComplete="new-password"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput,
                },
              }}
              value={this.state.password}
              onChange={(e) => {
                this.updateFieldValue("password", e);
              }}
              style={{ marginBottom: 4 }}
              InputLabelProps={{
                shrink: true,
                className: classes.bootstrapFormLabel,
              }}
            />
            <TextField
              type="password"
              fullWidth
              defaultValue="Password"
              label="Repeat Password"
              id="passwordinput2"
              autoComplete="new-password"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput,
                },
              }}
              value={this.state.password2}
              onChange={(e) => {
                this.updateFieldValue("password2", e);
              }}
              style={{ marginBottom: 4 }}
              InputLabelProps={{
                shrink: true,
                className: classes.bootstrapFormLabel,
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.acceptTos}
                  onChange={(e) => {
                    this.updateCheckboxValue("acceptTos", e);
                  }}
                  name="acceptTos"
                  color="primary"
                />
              }
              label={
                <div>
                  I understand and agree to{" "}
                  <span style={{ textDecoration: "underline" }} onClick={this.openPrivacyPolicy}>
                    Privacy Policy
                  </span>{" "}
                  and{" "}
                  <span style={{ textDecoration: "underline" }} onClick={this.openTos}>
                    Terms of Service
                  </span>
                </div>
              }
            />
            <input style={{ display: "none" }} type="submit" value="Submit" />
            <div>
              <DTButton
                onClick={() => {
                  this.performRegister();
                }}
                primary
                style={{ width: "100%", marginTop: 10, padding: 10 }}
              >
                Signup
              </DTButton>
            </div>
          </form>
        </div>
      );
    } else if (this.state.mode === "forgotPassword") {
      if (this.state.loading) {
        return (
          <div>
            <CircularProgress />
          </div>
        );
      }
      return (
        <div>
          <form id="forgotPassword" onSubmit={this.performForgotPassword}>
            <TextField
              fullWidth
              defaultValue=""
              label="E-mail"
              id="usernameinput1"
              autoComplete="username"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.bootstrapRoot,
                  input: classes.bootstrapInput,
                },
              }}
              style={{ marginBottom: 4 }}
              InputLabelProps={{
                shrink: true,
                className: classes.bootstrapFormLabel,
              }}
              value={this.state.username}
              onChange={(e) => {
                this.updateFieldValue("username", e);
              }}
            />
            <input style={{ display: "none" }} type="submit" value="Submit" />
            <div>
              <DTButton
                onClick={this.performForgotPassword}
                primary
                style={{ width: "100%", marginTop: 10, padding: 10 }}
              >
                Reset password
              </DTButton>
            </div>
          </form>
        </div>
      );
    }
  }
  getLoginForm(data) {
    const { classes } = this.props;
    if (this.state.mode === "register") {
      return (
        <div className={this.props.classes.loginForm}>
          <div className="flex flex-row space-between">
            <div className=" text-2xl sm:text-4xl ">Sign up. Its Free.</div>
            <div
              className=" flex-grow min-w-30 text-sm text-right align-text-bottom items-end justify-end"
              style={{ cursor: "pointer", textDecoration: "underline", placeSelf: "flex-end" }}
              onClick={() => {
                this.changeMode("login");
              }}
            >
              log in here
            </div>
          </div>

          <div className="flex flex-row my-5 justify-center ">
            <GoogleLoginButton buttonText="Sign up with Google" />
          </div>

          <div className="flex flex-row mb-4 mt-6">
            <div className="w-full text-center text-gray-500">Or sign up with email below</div>
          </div>
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <hr />
          </div>
          {this.getFormFields()}
        </div>
      );
    } else if (this.state.mode === "login") {
      return (
        <div className={this.props.classes.loginForm}>
          <div className="flex flex-row flex-wrap space-between">
            <div className="w-1/2 text-2xl sm:text-4xl">Log in</div>
            <div
              className="w-1/2 text-sm text-right align-text-bottom items-end"
              style={{ cursor: "pointer", textDecoration: "underline", placeSelf: "flex-end" }}
              onClick={() => {
                this.changeMode("register");
              }}
            >
              sign up here
            </div>
          </div>

          <div className="flex flex-row my-5 justify-center ">
            <GoogleLoginButton buttonText="Log in with Google" />
          </div>

          <div className="flex flex-row mb-4 mt-6">
            <div className="w-full text-center text-gray-500">Or login with email and password</div>
          </div>
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <hr />
          </div>
          {this.getFormFields()}
        </div>
      );
    } else if (this.state.mode === "forgotPassword") {
      return (
        <div className={this.props.classes.loginForm}>
          <Typography variant="h2" gutterBottom>
            Get Started
          </Typography>
          <Typography variant="body2" gutterBottom>
            Please enter your email address to reset your password.
            <br />
            <span
              onClick={() => {
                this.changeMode("login");
              }}
            >
              <DTButton primaryText>click here to log in</DTButton>
            </span>
          </Typography>
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <hr />
          </div>
          {this.getFormFields()}
        </div>
      );
    }
  }

  getContents(data) {
    const { classes } = this.props;
    if (this.state.mode === "login") {
      return (
        <div style={{ marginTop: "2em", height: "70vh" }}>
          <div className="flex flex-row space-between">
            <div className="w-1/3 text-4xl">Log in</div>
            <div
              className="w-2/3 text-sm text-right align-text-bottom items-end"
              style={{ cursor: "pointer", textDecoration: "underline", placeSelf: "flex-end" }}
              onClick={() => {
                this.changeMode("register");
              }}
            >
              or click here to sign up
            </div>
          </div>

          <div className="flex flex-row my-5 justify-center ">
            <GoogleLoginButton buttonText="Log in with Google" />
          </div>

          <div className="flex flex-row mb-4 mt-6">
            <div className="w-full text-center text-gray-500">Or log in with email and password</div>
          </div>
          <div>{this.getFormFields()}</div>
          <div style={{ paddingTop: "2em" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ textAlign: "center" }}></Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}></Grid>
            </Grid>
          </div>
        </div>
      );
    } else if (this.state.mode === "register") {
      return (
        <div>
          <div className="flex flex-row flex-wrap space-between">
            <div className="w-full md:w-full text-xl sm:text-2xl md:text-3xl ">
              Sign up to automate.video. Its Free.
            </div>
            <div
              className="w-full text-sm text-right align-text-bottom items-end"
              style={{ cursor: "pointer", textDecoration: "underline", placeSelf: "flex-end" }}
              onClick={() => {
                this.changeMode("login");
              }}
            >
              or click here to log in
            </div>
          </div>

          <div className="flex flex-row my-5 justify-center ">
            <GoogleLoginButton buttonText="Sign up with Google" />
          </div>

          <div className="flex flex-row mb-4 mt-6">
            <div className="w-full text-center text-gray-500">Or sign up with the form below</div>
          </div>
          <div>{this.getFormFields()}</div>
        </div>
      );
    } else if (this.state.mode === "forgotPassword") {
      return (
        <div>
          <Typography style={{ fontSize: "2.3em" }} variant="h1" className={classes.largeDisplaySectionHeading}>
            Its Free, Join Us
          </Typography>
          <div style={{ paddingTop: "1em" }}>Please enter your email address to reset your password.</div>
          <div style={{ paddingTop: "2em" }}>{this.getFormFields()}</div>
        </div>
      );
    }
  }
  changeMode(mode) {
    this.setState({ mode: mode });
  }
  render() {
    const { classes } = this.props;
    let j = JSON.stringify(this.props.edit_ready);

    let redirectUrl = "/uploads";

    let redirectString = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).redirect;

    if (redirectString && redirectString.length > 0) redirectUrl = redirectString;

    window.location.href = config.player + "/login";
    return <CircularProgress />;

    return (
      <div>
        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              // You can decide how and when you want to force reload
              refreshCacheAndReload();
            }
            return (
              <div>
                <AppHeader rt={this.props.location} />
                <LoginDialogPopup
                  title="Privacy Policy"
                  url="/terms/privacy-policy.html"
                  open={this.state.openPrivacyPolicy}
                  onClose={this.closePrivacyPolicy}
                />
                <LoginDialogPopup
                  title="Terms Of Service"
                  url="/terms/terms-of-service.html"
                  open={this.state.openTOS}
                  onClose={this.closeTos}
                />
                <Hidden smUp>
                  <div className={this.props.classes.loginDivSmall}>
                    <Grid container hidden={{ smUp: true }}>
                      <Grid item xs>
                        {!this.props.user.id ? (
                          this.getLoginForm()
                        ) : (
                          <div style={{ position: "relative", zIndex: 1000, padding: "1em" }}>
                            <DTButton
                              primary
                              large
                              onClick={() => {
                                this.props.history.push(redirectUrl);
                              }}
                            >
                              Continue to Dashboard
                            </DTButton>
                            <Redirect to={redirectUrl} />
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Hidden>
                <Hidden xsDown>
                  <div className={this.props.classes.loginDiv}>
                    <Grid container hidden={{ xsDown: true }}>
                      <Grid item md={6} sm={4} xl={6}>
                        <div></div>
                      </Grid>
                      <Grid item md={6} sm={8} xl={6} style={{ position: "relative" }}>
                        <div style={{ margin: "0px auto" }}>
                          {!this.props.user.id ? (
                            <div style={{ position: "relative", zIndex: 1000 }} className={classes.largeDisplaySection}>
                              {this.getContents()}
                            </div>
                          ) : (
                            <div style={{ position: "relative", zIndex: 1000, padding: "1em" }}>
                              <DTButton
                                primary
                                large
                                onClick={() => {
                                  this.props.history.push(redirectUrl);
                                }}
                              >
                                Continue to Dashboard
                              </DTButton>
                              <Redirect to={redirectUrl} />
                            </div>
                          )}
                          <div className={this.props.classes.loginFormSection}></div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Hidden>
              </div>
            );
          }}
        </CacheBuster>
      </div>
    );
  }
}

function mapStateToProps(state, prop) {
  return {
    user: state.user,
    edit_ready: state.edit_ready,
    loadinglogin: state.loadinglogin,
    loginmsg: state.loginmsg,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    action: bindActionCreators(Actions, dispatch),
  };
}

function validateEmail(email) {
  var re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return re.test(email);
}

/*
function validateEmail(email) {
  var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(email);
} */

// export default Login;
Login = withStyles(styles, { name: "Login" })(Login);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
