export default (state = { data: [], loading: false, totalHits: 0, search: "" }, payload) => {
  let newDat = null;
  switch (payload.type) {
    case "ML_PIXABAY_IMAGES_READY":
      // console.log("pload", payload.data)
      newDat = fillBuffer(state, payload, "ready");
      return { loading: false, data: newDat, totalHits: payload.data.totalHits, search: payload.data.search };
    case "ML_PIXABAY_IMAGES_LOADING":
      newDat = fillBuffer(state, payload, "loading");
      console.log("ML_PIXABAY_IMAGES_LOADING", payload);
      return { loading: true, data: newDat, totalHits: payload.data.totalHits, search: payload.data.search };
    default:
      return state;
  }
};

function fillBuffer(state, payload, stype) {
  var newDat = [];
  var oldType = "";
  var newType = "";
  if (state.data.length > 0) {
    var prevItem = state.data[0];
    if (prevItem.videos) {
      oldType = "videos";
    }
    if (prevItem.imageWidth) {
      oldType = "images";
    }
  }
  if (payload.data.data.length > 0) {
    var newItem = payload.data.data[0];
    // console.log()
    if (newItem.imageWidth) {
      newType = "images";
    }
    if (newItem.videos) {
      newType = "videos";
    }
    // newType = newItem;
  }
  // if (stype === "ready"){
  // 	console.log("Types = ", oldType +"-"+ newType );
  // }

  if (state.search === payload.data.search) {
    var doRun = true;
    if (stype === "ready") {
      if (oldType !== "" && oldType !== newType) {
        doRun = false;
      }
    }
    if (doRun) {
      for (let i = 0; i < state.data.length; i++) {
        let item = state.data[i];
        newDat.push(item);
      }
    }
  }
  for (let i = 0; i < payload.data.data.length; i++) {
    let item = payload.data.data[i];
    newDat.push(item);
  }
  return newDat;
}
