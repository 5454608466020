export default (state = { prevLength: 0, newLength: 0 }, payload) => {
  switch (payload.type) {
    case "MEDIALIBRARY":
      let resp = {};
      if (resp.prevLength === 0) {
        resp.prevLength = JSON.parse(payload.data).length;
      } else {
        resp.prevLength = resp.newLength;
      }
      resp.newLength = JSON.parse(payload.data).length;
      return resp;
    // case 'MEDIA_LIBRARY_DRAWER':
    //     return payload.data;
    default:
      return state;
  }
};
