import store from "../store";

export default (state = null, payload) => {
  // console.log("PAYLOAD---", payload.type)
  switch (payload.type) {
    /* case "UPDATE_VIDEO":
      if (store) {
     
        console.log("Updating video = ", payload);
        if (!state || !state.placeholderid) {
          return state;
        }
        const pid = state.placeholderid;
        //const { scenes } = payload.data;
        //const sceneid = state.selected_scene.sceneid;
        if (
          state.hasOwnProperty("selected_scene") &&
          state.selected_scene.hasOwnProperty("sceneid") &&
          state.selected_scene.sceneid !== null
        )
          for (let i = 0; i < payload.data.scenes.length; i++) {
            const scene = payload.data.scenes[i];
            if (scene.sceneid === state.selected_scene.sceneid) {
              const placeholders = scene.placeholders;
              for (let j = 0; j < placeholders.length; j++) {
                if (placeholders[j].placeholderid === pid) {
                  return placeholders[j];
                }
              }
            }
          }
        return state;
      } else {
        return state;
      }*/
    case "EXPAND_TEXT_SUCCESS":
      return { placeholderid: payload.data.placeholderid, text: payload.text };

    case "SELECT_ASSET":
      return { ...payload.data };
    // break;
    case ("DELETE_PLACEHOLDER", "SELECT_SCENE"):
      return null;
    default:
      return state;
  }
};
