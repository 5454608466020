const config = require("../config");

import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as Actions from "../actions";

import { useEffect } from "react";
import AvatarInstructions from "./AvatarInstructions";

export default function UserProfilePreferencesDialog(props) {
  const preferencesCategory = useSelector((state) => state.user.info.preferencesCategory);
  const preferencesSegment = useSelector((state) => state.user.info.preferencesSegment);
  const [category, setCategory] = useState(null);
  const [segment, setSegment] = useState(null);
  const [userSource, setUserSource] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (preferencesCategory) setCategory(preferencesCategory);
  }, [preferencesCategory]);

  useEffect(() => {
    if (preferencesSegment) setSegment(preferencesSegment);
  }, [preferencesSegment]);

  const dispatch = useDispatch();

  const categories = {
    Business: [
      "Corporate Promotion",
      "Internal Business Presentation",
      "Customer Training and Onboarding",
      "Product Tutorials",
      "Digital Marketing & Promotion",
      "eCommerce",
      "Employee Engagement & HR",
      "Virtual Events & Seminars",
      "Social Media",
      "Digital Ads",
      "Other",
    ],
    "Digital & Creative": ["Social Media", "Personal", "Streaming", "Digital Ads", "Other"],
    Teaching: ["Higher Education", "School", "Consumer/Creative", "Other"],
    Other: ["Other"],
  };

  const userSources = [
    "Google search",
    "Friends or Colleagues",
    "Watched a video on automate.video",
    "Blog/Article",
    "YouTube",
    "Facebook",
    "Instagram",
    "Twitter",
    "LinkedIn",
    "Other",
  ];

  const done = (
    <div className="bg-blue-dark h-6 w-6 rounded-full shadow flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-check"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="#FFFFFF"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <path d="M5 12l5 5l10 -10" />
      </svg>
    </div>
  );

  return (
    <React.Fragment>
      {props.open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full md:w-9/12 my-6 mx-auto">
              {/*content*/}
              <div className="min-h-full text-gray-600 border-0 py-4 px-6 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none justify-between">
                {/*body*/}
                <div className="flex flex-col justify-start">
                  <div className="relative gap-1 flex flex-row w-auto flex-wrap text-2xl pb-4">
                    Preferences (can be changed anytime)
                  </div>

                  <div className="mt-6">
                    <h2 className="sr-only">Steps</h2>

                    <div className="relative after:absolute after:inset-x-0 after:top-1/2 after:block after:h-0.5 after:-translate-y-1/2 after:rounded-lg after:bg-gray-100">
                      <ol className="relative z-10 flex justify-between text-sm font-medium text-gray-500">
                        <li className="flex items-center bg-white p-2">
                          <span
                            className={
                              "h-6 w-6 rounded-full text-center text-[14px] font-bold leading-6" +
                              (page === 1 ? " bg-orange text-white" : " bg-gray-100 ")
                            }
                          >
                            {page > 1 ? done : "1"}
                          </span>

                          <span className="hidden sm:ml-2 sm:block text-xl font-bold"> Step 1 </span>
                        </li>

                        <li className="flex items-center bg-white p-2">
                          <span
                            className={
                              "h-6 w-6 rounded-full text-center text-[14px] font-bold leading-6" +
                              (page === 2 ? " bg-orange text-white" : " bg-gray-100 ")
                            }
                          >
                            {page > 2 ? done : "2"}
                          </span>

                          <span className="hidden sm:ml-2 sm:block text-xl font-bold"> Step 2 </span>
                        </li>

                        <li className="flex items-center bg-white p-2">
                          <span
                            className={
                              "h-6 w-6 rounded-full text-center text-[14px] font-bold leading-6" +
                              (page === 3 ? " bg-orange text-white" : " bg-gray-100 ")
                            }
                          >
                            {page > 3 ? done : "3"}
                          </span>

                          <span className="hidden sm:ml-2 sm:block  text-xl font-bold"> Step 3 </span>
                        </li>

                        <li className="flex items-center bg-white p-2">
                          <span
                            className={
                              "h-6 w-6 rounded-full text-center text-[14px] font-bold leading-6" +
                              (page === 4 ? " bg-orange text-white" : " bg-gray-100 ")
                            }
                          >
                            {page > 4 ? done : "4"}
                          </span>

                          <span className="hidden sm:ml-2 sm:block  text-xl font-bold"> Done </span>
                        </li>
                      </ol>
                    </div>
                  </div>

                  <div className="mt-6 p-6 border-2">
                    {page < 3 ? (
                      <div className="relative gap-1 flex flex-row w-auto flex-wrap text-xl pb-4 ">
                        We develop based on your demand. Please share with us your intent in using automate.video. Your
                        feedback is important to us.
                      </div>
                    ) : null}
                    <div className="relative gap-1 flex flex-row w-auto font-bold flex-wrap text-xl pb-4">
                      {page === 1 ? "I will use automate.video mainly for" : null}
                      {page === 2 ? "I mainly create videos for" : null}
                      {page === 3 ? "How did you hear about us?" : null}
                    </div>
                    {page === 1 ? (
                      <div className="flex flex-row w-full flex-wrap my-2">
                        {Object.keys(categories)?.map((cat) => (
                          <div
                            className={
                              "w-52 m-2 border-2  cursor-pointer rounded py-2 " +
                              (cat === category ? "border-gray-500 bg-gray-200" : "bg-white")
                            }
                          >
                            <div
                              className="block mx-2 text-center text-gray-500 text-xl sm:font-bold "
                              onClick={() => {
                                setCategory(cat);
                                setPage(2);
                              }}
                            >
                              {cat}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {page === 2 ? (
                      <div className="flex flex-row w-full flex-wrap my-2">
                        {category
                          ? categories?.[category]?.map((seg) => (
                              <div
                                className={
                                  "w-52 m-2 border-2  cursor-pointer rounded py-2 " +
                                  (seg === segment ? "border-gray-500 bg-gray-200" : "bg-white")
                                }
                              >
                                <div
                                  className="block mx-2 text-center text-gray-500 text-xl sm:font-bold "
                                  onClick={() => {
                                    setSegment(seg);
                                    setPage(3);
                                  }}
                                >
                                  {seg}
                                </div>
                              </div>
                            ))
                          : null}
                      </div>
                    ) : null}
                    {page === 3 ? (
                      <div className="flex flex-row w-full flex-wrap my-2">
                        {userSources?.map((uS) => (
                          <div
                            className={
                              "w-52 m-2 border-2  cursor-pointer rounded py-2 " +
                              (uS === userSource ? "border-gray-500 bg-gray-200" : "bg-white")
                            }
                          >
                            <div
                              className="block mx-2 text-center text-gray-500 text-xl sm:font-bold "
                              onClick={() => {
                                setUserSource(uS);
                                setPage(4);
                              }}
                            >
                              {uS}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                    {page === 4 ? <AvatarInstructions /> : null}
                  </div>
                </div>

                {/*footer*/}
                <div className="flex flex-row w-full justify-self-end self-end ">
                  <div className="flex flex-row w-full content-between justify-between mt-3 pt-1">
                    <div className="mb-1 mt-3">
                      {page > 1 ? (
                        <button
                          className="text-blue background-transparent cursor-pointer uppercase text-sm text-gray-500 outline-none focus:outline-none "
                          type="button"
                          style={{ transition: "all .15s ease" }}
                          onClick={() => {
                            setPage(page - 1);
                          }}
                        >
                          Go Back
                        </button>
                      ) : null}
                    </div>
                    <div className="flex items-center justify-end gap-4  ">
                      <button
                        className="text-blue background-transparent cursor-pointer uppercase text-sm text-gray-500 outline-none focus:outline-none  mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={() => {
                          setSegment(null);
                          setCategory(null);
                          setPage(1);
                        }}
                      >
                        Clear
                      </button>

                      <button
                        className="py-2 px-5 mr-2 mb-2 text-sm font-bold uppercase text-gray-700 focus:outline-none bg-gray-200 rounded-lg border border-gray-200 hover:bg-gray-300  focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                        onClick={() => {
                          let info = {};
                          info.preferencesSegment = segment;
                          info.preferencesCategory = category;
                          info.preferencesUserSource = userSource;
                          dispatch(Actions.updateUserInfo(info));
                          props.handleClose();
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </React.Fragment>
  );
}
