export default (state = null, payload) => {
  // console.log("PAYLOAD---", payload.type)
  switch (payload.type) {
    case "GET_WORK_HISTORY":
    case "WORK_HISTORY":
    case "CLEAR_SELECTED_SCENES":
      return null;
    case "SELECT_SCENE":
      return { ...payload.data.scene };
    // break;
    case "UPDATE_VIDEO":
      if (state && state.sceneid) {
        for (var i = payload.data.scenes.length - 1; i >= 0; i--) {
          if (payload.data.scenes[i].sceneid === state.sceneid) {
            /*let items = payload.data.scenes[i].placeholders.filter(item => item.type === "media/audio");
            console.log(`Current audio items = ${items.length}`);*/
            return { ...payload.data.scenes[i] };
          }
        }
      }

    default:
      return state;
  }
};
