import React from "react";
import { BrowserRouter as Router, Route, Link, Redirect, withRouter } from "react-router-dom";

export default class Home extends React.Component {
  render() {
    return (
      <div>
        <div style={{ textAlign: "center", marginTop: "2em" }}>
          <Link to="/uploads">Click here to continue...</Link>
          <Redirect
            to={{
              pathname: "/uploads",
              // state: { from: props.location }
            }}
          />
        </div>
      </div>
    );
  }
}
