import React, { useEffect } from "react";
import qs from "qs";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../actions";
import config from "../config";

import { Redirect, useLocation } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";

export default function TokenLogin(props) {
  const userid = useSelector((state) => state.user.id);
  const token_login_failure = useSelector((state) => state.user.token_login_failure);
  const access_token_login_failure = useSelector((state) => state.user.access_token_login_failure);
  let location = useLocation();
  let loginToken = qs.parse(location.search, { ignoreQueryPrefix: true }).token;
  let accessToken = qs.parse(location.search, { ignoreQueryPrefix: true }).accessToken;
  const redirectArr = location.search.split("redirect=");
  let redirectString = null;
  if (redirectArr && redirectArr[1]) redirectString = redirectArr[1];
  // let redirectString = qs.parse(location.search, { ignoreQueryPrefix: true }).redirect;

  const dispatch = useDispatch();

  useEffect(() => {
    if (loginToken) dispatch(Actions.loginWithToken(loginToken));
    if (accessToken) {
      dispatch(Actions.loginWithAccessToken(accessToken));
      localStorage.setItem("dt_access_token", accessToken);
    }
  }, []);

  let redirectUrl = "/uploads";

  if (redirectString && redirectString.length > 0) redirectUrl = redirectString;
  console.log("redirectUrl", redirectUrl);

  if (access_token_login_failure) window.location.href = config.player + "/login";
  if (token_login_failure) return <Redirect to={"/login"} />;
  if (userid) return <Redirect to={redirectUrl} />;
  return <CircularProgress />;
}
