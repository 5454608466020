export default(state=[], payload) => {
    switch(payload.type) {
        case 'EDIT_READY':
        	let cp = state;
        	cp.push(payload.data);
            return cp; 
        default:
            return state;
    }
}

