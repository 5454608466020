export default (state = {}, payload) => {
  switch (payload.type) {
    case "CREATE_STRIPE_PORTAL_SESSION_SUCCESS":
      return { ...state, redirect_url: payload.data.redirect_url };
    case "STRIPE_PORTAL_SESSION_CLEAR":
      return { ...state, redirect_url: null };

    default:
      return state;
  }
};
