export default (state = {}, payload) => {
  switch (payload.type) {
    case "CREDITS_ADD_PENDING_SUCCESS":
      console.log(payload.data);
      return payload.data;

    default:
      return state;
  }
};
