// round and calculate annual to monthly
export const annualToMonthly = (annual) => {
  if (annual > 99) return Math.round(annual / 12);
  return Math.round((annual / 12) * 100) / 100;
};

// format monthly price
export const formatMonthlyPrice = (price) => {
  if (Number.isInteger(price)) return price;
  else return price.toFixed(2);
};
