import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../actions";

import DTButton from "../common/theme/DTButton";

function CancelSubscriptionDialog(props) {
  const dispatch = useDispatch();
  const [cancellationMessage,setCancellationMessage] = React.useState("");

  return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Cancel Subscription</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Cancelling your subscription will lead to <strong>deletion of all your private projects.</strong>{ " "}
            Are you absolutely sure?
          </DialogContentText>
         
          <TextField
            autoFocus
            multiline
            
            rows={4}
            variant="outlined"
            margin="dense"
            value={cancellationMessage}
            onChange={(e) => {
              
              setCancellationMessage(e.target.value);
            }}
            id="cancellation-message"
            label="Reason for cancellation"
            type="text"
            fullWidth
          />
        
        </DialogContent>
        <DialogActions>
          <DTButton onClick={props.handleClose} primary>
            NO, KEEP THE SUBSCRIPTION
          </DTButton>
          <DTButton
            onClick={() => {
              dispatch(Actions.sendSupportTicket( { type:"CANCELLATION", message: cancellationMessage} ));
              props.handleClose({cancellation: true});
            }}
            style={{ color: "white", backgroundColor: "darkred" }}
          >
            YES, CANCEL MY SUBSCRIPTION
          </DTButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CancelSubscriptionDialog;
