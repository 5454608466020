import videojs from "video.js";

export default class VideoEditor {
  buildVideoTemp(blob, cb = null) {
    var url = URL.createObjectURL(blob);
    var video = document.createElement("video");
    var timeupdate = function(e) {
      console.log("Ts=", e.timeStamp);
      if (e.timeStamp > 7000) {
        snapImage();
        video.removeEventListener("timeupdate", timeupdate);
        video.pause();
      }
    };
    video.addEventListener("loadeddata", function() {
      // if (snapImage()) {
      // video.removeEventListener('timeupdate', timeupdate);
      // }
    });
    var snapImage = function() {
      var canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
      var image = canvas.toDataURL();
      var success = image.length > 100000;
      if (success) {
        var img = document.createElement("img");
        img.src = image;
        // document.getElementsByTagName('div')[0].appendChild(img);
        // file.image = image;
        // previews[index] = file.image;
        // that.setState({previews:previews})
        // console.log(img)
        if (cb) cb(image);
        URL.revokeObjectURL(url);
        // return img;
      }
      return success;
    };
    video.addEventListener("timeupdate", timeupdate);
    video.preload = "metadata";
    video.src = url;
    // video.addEventListener("loadedmetadata", function() {

    //      video.play();
    //      video.pause();
    //      video.currentTime = 10;
    //      video.play();

    // }, false);

    video.addEventListener("canplay", function() {
      this.currentTime = 10;
    });
    video.play();
    console.log("video = ", video);
    // Load video in Safari / IE11
    video.muted = true;
    // video.playsInline = true;
    // video.pause();
    // video.currentTime = 10;
    // this.seekToTime(video, 10);
  }

  seekToTime(video_element, ts) {
    // try and avoid pauses after seeking
    console.log("seekToTime");
    video_element.pause();
    video_element.currentTime = ts; // if this is far enough away from current, it implies a "play" call as well...oddly. I mean seriously that is junk.
    // however if it close enough, then we need to call play manually
    // some shenanigans to try and work around this:
    var timer = setInterval(function() {
      if ((video_element.paused && video_element.readyState === 4) || !video_element.paused) {
        video_element.play();
        clearInterval(timer);
      }
    }, 50);
  }

  buildPreview2(cb = null, allowedThumbnails = 8, dataurl) {
    var video = document.createElement("video");
    var player = null;
    videojs(
      video,
      {
        autoplay: false,
        muted: true,
        // src: '/SampleVideo.mp4',
        sources: [
          {
            src: dataurl,
            type: "video/mp4"
          }
        ]
      },
      function onPlayerReady() {
        var snapImage = function() {
          console.log("Snapping image");
          var canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
          var image = canvas.toDataURL();
          // var success = image.length > 100000;
          return image;
          // if (cb)
          // 	cb(image)
          // if (success) {

          // }
          // return success;
        };
        player = this;
        var LengthOfVideo = 0;
        var Passed = 0;
        var Jump = 1;
        // allowedThumbnails--;
        player.on("loadeddata", function(e) {
          // LengthOfVideo = this.duration();
          // Jump = Math.round(LengthOfVideo/allowedThumbnails);
          // player.play();
        });

        player.on("timeupdate", function(e) {
          var c = Math.round(player.currentTime());
          if (c === 0 || c === 1) {
            LengthOfVideo = this.duration();
            Jump = Math.round(LengthOfVideo / allowedThumbnails);
          }
          console.log(c);
          if (Passed > LengthOfVideo) {
            player.pause();
            // this.stop();
          } else {
            var image = snapImage();
            var success = image.length > 100000;
            if (success) {
              cb(image);
              Passed += Jump;
              player.currentTime(Passed);
            } else {
              // console.log("Image incorrect")
              Passed += 1;
              player.currentTime(Passed);
            }
          }
        });

        // player.currentTime(14);
        player.play();

        // player.pause();
      }
    );
    console.log(video);
  }

  buildPreview(cb = null) {
    //
    var that = this;
    var xhttp = new XMLHttpRequest();
    xhttp.responseType = "arraybuffer";
    xhttp.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        // console.log(this)
        var blob = new Blob([this.response], { type: "video/mp4" });
        // console.log(blob);
        that.buildVideoTemp(blob, cb);
        // Action to be performed when the document is read;
      }
    };
    //xhttp.open("GET", "/SampleVideo.mp4", true);
    xhttp.send();
  }
}

/**
 * Captures a image frame from the provided video element.
 *
 * @param {Video} video HTML5 video element from where the image frame will be captured.
 * @param {Number} scaleFactor Factor to scale the canvas element that will be return. This is an optional parameter.
 *
 * @return {Canvas}
 */
// function capture(video, scaleFactor) {
// 	if(scaleFactor === null){
// 		scaleFactor = 1;
// 	}
// 	var w = video.videoWidth * scaleFactor;
// 	var h = video.videoHeight * scaleFactor;
// 	var canvas = document.createElement('canvas');
// 		canvas.width  = w;
// 	    canvas.height = h;
// 	var ctx = canvas.getContext('2d');
// 		ctx.drawImage(video, 0, 0, w, h);
//     return canvas;
// }

/**
 * Invokes the <code>capture</code> function and attaches the canvas element to the DOM.
 */
// function shoot(){
// 	var video  = document.getElementById(videoId);
// 	var output = document.getElementById('output');
// 	var canvas = capture(video, scaleFactor);
// 		canvas.onclick = function(){
// 			window.open(this.toDataURL());
// 		};
// 	snapshots.unshift(canvas);
// 	output.innerHTML = '';
// 	for(var i=0; i<4; i++){
// 		output.appendChild(snapshots[i]);
// 	}
// }
