import React, { useState, useEffect } from "react";
import config from "../../../config";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";

import CardHeader from "@material-ui/core/CardHeader";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import StarIcon from "@material-ui/icons/StarBorder";

import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

import pricelist from "./pricelist.json";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../../../actions";

import { Helmet } from "react-helmet";

import AppHeaderNew from "../../../sharedcomponents/AppHeaderNew";

import tiers from "./tiers";

import ConfirmPlanUpgrade from "./ConfirmPlanUpgrade";

import { annualToMonthly, formatMonthlyPrice } from "./formatters";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.automate.video">
        Automate.video
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },

  cardHeader: {
    backgroundColor: theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

export default function Pricing(props) {
  const [country, setCountry] = useState(null);
  const [products, setProducts] = useState(tiers);
  const [currency, setCurrency] = useState("EUR");
  //const [currencySymbol, setCurrencySymbol] = useState("€");
  const [pricesIndex, setPricesIndex] = useState(null);
  const [availableCurrencies, setAvailableCurrencies] = useState(["EUR"]);
  const user = useSelector((state) => state.user);

  const [countryData, setCountryData] = useState(null);
  const [annualToggle, setAnnualToggle] = useState(user?.plan_interval === "month" ? false : true);
  const [debugCountry, setDebugCountry] = useState(null);

  const user_country = useSelector((state) => state.user_country);

  const [plan, setPlan] = useState(null);
  const [planCurrency, setPlanCurrency] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const [coupon, setCoupon] = useState(null);
  let discountPercentage = 0.3;

  const [confirmSubscription, setConfirmSubscription] = useState(null);

  const handlePlanUpgrade = () => {
    window.location.href = confirmSubscription.url;

    setConfirmSubscription(null);
  };

  const handleCancelUpgrade = () => {
    setConfirmSubscription(null);
  };

  // country list for debugging
  const countries = pricelist.map((p) => p.name).sort();

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV !== "production") setCoupon("yKxejmQs");
    if (process.env.REACT_APP_NODE_ENV === "production") setCoupon("hKAuslBo");
  }, [props.coupon]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user && user.id) setUserLoggedIn(true);
    else setUserLoggedIn(false);
    if (user && user.attributes && user?.attributes?.plan_status === "active") {
      if (user.attributes.hasOwnProperty("plan")) setPlan(user.attributes.plan);
      //if (user.attributes.hasOwnProperty("plan_subcriptionid")) setSubscriptionid(user.attributes.plan_subscriptionid);
      if (user?.attributes?.plan_country) setCountry(user.attributes.plan_country);
      if (user?.attributes?.plan_currency) setPlanCurrency(user.attributes.plan_currency);
    }
  }, [user]);

  useEffect(() => {
    if (props.ipv4 && props.ipv4 !== null) dispatch(Actions.getUserCountry({ ipv4: props.ipv4 }));
  }, [props.ipv4]);

  useEffect(() => {
    if (country == null && user_country.hasOwnProperty("country")) setCountry(user_country.country);
  }, [user_country]);

  useEffect(() => {
    let cca2 = country || "FI";
    if (debugCountry) cca2 = pricelist.find((p) => p.name === debugCountry).cca2;

    for (let c of pricelist) {
      if (c.cca2 === cca2) setCountryData(c);
    }
  }, [country, debugCountry]);

  useEffect(() => {
    const availableCurrencies = countryData?.prices?.map((p) => p.currency);
    let foundActivePlanIndex = availableCurrencies?.findIndex((c) => c === planCurrency);
    let priceIndex = foundActivePlanIndex === -1 ? 0 : foundActivePlanIndex;
    console.log({ availableCurrencies, priceIndex });
    if (countryData && countryData.prices && countryData.prices[priceIndex]) {
      setCurrency(countryData.prices[priceIndex].currency);
      //setCurrencySymbol(countryData.prices[0].currencySymbol);
      setPricesIndex(priceIndex);
      setAvailableCurrencies(countryData?.prices?.map((p) => p.currency));
    }
  }, [countryData, planCurrency]);

  const selectCurrencySymbol = (prices) => {
    if (prices.currency === "EUR" || prices.currency === "USD") return prices.currencySymbol;
    return prices.currency;
  };

  useEffect(() => {
    if (countryData && countryData.prices && countryData.prices[pricesIndex] && countryData.prices[pricesIndex].plans) {
      for (let p of countryData.prices[pricesIndex].plans)
        setProducts(
          products.map((item, index) => {
            return item.plan_name === p.plan_name
              ? Object.assign(item, p, {
                  zero_decimal: countryData.prices[pricesIndex].zero_decimal,
                  currency: countryData.prices[pricesIndex].currency,
                  currencySymbol: selectCurrencySymbol(countryData.prices[pricesIndex]),
                  payment_method: "stripe",
                  ip: props.ipv4,
                  noTrialPeriod: countryData.noTrialPeriod,
                })
              : item;
          })
        );
    }
  }, [countryData, currency, pricesIndex]);

  // define font size for pricing line
  let pricingFontSize = " text-4xl";
  let currentTier = null;
  let currentTierAnnualToggle = user?.attributes?.plan_interval === "year";

  if (products)
    for (let p of products) {
      if (annualToggle && p.price_annual / 12 > 999) pricingFontSize = " text-3xl ";
      else if (p.price_monthly > 999) pricingFontSize = " text-3xl ";
      if (p.plan_name === user?.attributes?.plan_name && user?.attributes.plan_status === "active") currentTier = p;
    }

  const getButtonText = (tier) => {
    if (tier.plan_name === "Free") return "Sign up for free";
    if (!userLoggedIn) {
      if (!tier.noTrialPeriod && tier.plan_name === "Basic") return "7 day free trial";
      else return "Subscribe";
    } else {
      if (plan === tier.plan) return "CURRENT PLAN";
      //if (!tier.noTrialPeriod && tier.plan_name === "Basic") return "7 day free trial";
      if (plan?.substring(0, 4) === "free") return "Subscribe";
      else return "CHANGE TO THIS PLAN";
    }
  };
  /*
  let allQueryParams = qs.parse(router.asPath.substring(router.asPath.indexOf("?")), {
    ignoreQueryPrefix: true,
  }); */

  let queryString = null;
  if (props.location.search.substr(1) && props.location.search.substr(1).length)
    queryString = props.location.search.substr(1);

  return (
    <React.Fragment>
      <Helmet>
        <title>Automate.video | Pricing </title>
      </Helmet>
      {userLoggedIn ? (
        ""
      ) : (
        <div className="mb-20">
          <AppHeaderNew queryString={queryString} />
        </div>
      )}
      <CssBaseline />
      <ConfirmPlanUpgrade
        open={confirmSubscription}
        currentPlan={user?.attributes?.plan_name}
        currentTier={currentTier}
        currentTierAnnualToggle={currentTierAnnualToggle}
        tier={confirmSubscription?.tier}
        annualToggle={confirmSubscription?.annualToggle}
        handleConfirm={handlePlanUpgrade}
        handleCancel={handleCancelUpgrade}
      />

      {user?.attributes?.plan_status !== "active" && (
        <div className="flex flex-row justify-end px-8 pt-8">
          <div className="relative group">
            <button className="py-2.5 px-3 w-full md:text-sm text-site bg-transparent border border-dimmed  focus:border-brand focus:outline-none focus:ring-0 peer flex items-center justify-between rounded font-semibold">
              {currency}
            </button>
            <div className="absolute z-[99] top-[100%] left-[50%] translate-x-[-50%] rounded-md overflow-hidden shadow-lg min-w-[200px] w-max peer-focus:visible peer-focus:opacity-100 opacity-0 invisible duration-200 p-1 bg-gray-100 dark:bg-gray-800  border border-dimmed text-xs md:text-sm">
              {availableCurrencies?.map((c, idx) => (
                <div
                  onClick={() => {
                    setPricesIndex(idx);
                    setCurrency(c);
                  }}
                  key={c}
                  className=" w-full block cursor-pointer hover:bg-white dark:hover:bg-gray-900 dark:bg-gray-800 hover:text-link px-3 py-2 rounded-md"
                >
                  {c}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <Container maxWidth="sm" component="main">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          gutterBottom
          style={{
            color: "#36355b",

            fontSize: "3em",
          }}
        >
          {props.campaign === "secret" ? "Secret Plans" : "Pricing"}
        </Typography>
        {props.campaign === "secret" || props.campaign === "virtualpresenterplan" ? null : (
          <Typography variant="h5" align="center" color="textSecondary" component="p">
            <span
              onClick={() => {
                window.location.href = config.player + "/login?mode=register";
                //props.history.push("/login?mode=register");
              }}
              style={{ fontWeight: 600, color: "rgb(78, 78, 112)", cursor: "pointer" }}
            >
              Sign up for free.
            </span>{" "}
            No credit card required.
          </Typography>
        )}

        <div className="w-full h-full flex flex-col justify-center items-center mt-4">
          <div className="flex justify-center items-center">
            <label htmlFor="Toggle2" className="inline-flex items-center space-x-4 cursor-pointer dark:text-gray-100">
              <span
                onClick={() => setAnnualToggle(false)}
                className={
                  "text-lg rounded-xl py-2 px-4" +
                  (!annualToggle ? " font-bold text-black-400 bg-blue-header" : " bg-gray-300")
                }
              >
                Monthly
              </span>
              {/* disable togle <span className="relative mt-3">
                <input
                  id="Toggle2"
                  type="checkbox"
                  className="hidden peer"
                  onClick={() => setAnnualToggle(!annualToggle)}
                  checked={annualToggle}
                />
                <div className="w-16 h-8 -mt-2 rounded-full shadow bg-blue-dark peer-checked:dark:bg-blue-dark"></div>
                <div className="absolute left-0 w-6 h-6 mx-1 rounded-full shadow -inset-y-1 peer-checked:right-0 peer-checked:left-auto bg-white"></div>
              </span> */}
              <span
                onClick={() => setAnnualToggle(true)}
                className={
                  "text-lg rounded-xl py-2 px-4" +
                  (annualToggle ? " font-bold text-black-400 bg-blue-header" : " bg-gray-300")
                }
              >
                💥 Yearly: get 6 months free
              </span>
            </label>
          </div>
        </div>

        {props.campaign === "virtualpresenterplan" ? null : (
          <Typography
            variant="h5"
            align="center"
            style={{ fontWeight: 600, color: "rgb(78, 78, 112)", marginTop: "0.5em" }}
            component="p"
          >
            {countryData?.noTrialPeriod || (userLoggedIn && plan?.substring(0, 4) !== "free")
              ? ""
              : "7 day free trial on Basic plan"}
          </Typography>
        )}
      </Container>
      {/* End hero unit */}
      {process.env.REACT_APP_NODE_ENV !== "production" ? (
        <div className="flex flex-row w-full justify-start px-24">
          <label htmlFor="debug-country">DEBUG: Choose country:</label>
          <select name="debug-country" id="debug-country" onChange={(e) => setDebugCountry(e.target.value)}>
            <option value="">Choose country</option>
            {countries.map((c) => (
              <option key={c} value={c}>
                {c}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      <Container maxWidth={false} component="main">
        <div className="flex flex-row flex-wrap justify-center p-4 items-stretch rounded-lg">
          {products.map((tier) => {
            if (tier.plan_name === "Free" && props.campaign === "secret") return null;
            if (tier.plan_name === "Free" && userLoggedIn) return null;
            if (tier.plan_name === "Free" && props.campaign === "virtualpresenterplan") return null;
            if (tier.plan_name === "Basic" && props.campaign === "virtualpresenterplan") return null;
            else
              return (
                <div
                  className="flex flex-row max-w-2xl md:max-w-md lg:max-w-sm xl:max-w-19 2xl:max-w-19 justify-center p-0"
                  item
                  key={tier.plan_name}
                  container
                >
                  <Grid
                    item
                    className="flex flex-col justify-between w-80 m-3 rounded-lg p-0 m-3"
                    component={Card}
                    key={tier.plan_name}
                    style={{
                      margin: 12,
                      // Use flex layout with column direction for components in the card
                      // (CardContent and CardActions)

                      padding: "0px",
                    }}
                  >
                    <div item className="flex flex-col rounded-lg justify-start">
                      <CardHeader
                        title={tier.plan_name}
                        subheader={tier.subheader}
                        titleTypographyProps={{ align: "center" }}
                        subheaderTypographyProps={{ align: "center" }}
                        action={tier.plan_name === "Professional" ? <StarIcon /> : null}
                        className="bg-blue-header "
                        style={{ height: 80 }}
                      />

                      <div className="flex flex-row mt-2 mb-2 justify-center align-baseline">
                        <ul style={{ paddingBottom: "0em" }}>
                          {props.campaign === "secret" ? (
                            <React.Fragment>
                              <Typography
                                component="li"
                                variant="h5"
                                color="textPrimary"
                                style={{
                                  color: "gray",
                                  fontSize: "1.8em",
                                  textDecoration: "line-through",
                                  textDecorationColor: "red",
                                  textDecorationStyle: "edges",
                                  textAlign: "center",
                                }}
                              >
                                <span className="text-gray-500 text-2xl">
                                  {" "}
                                  {tier.currencySymbol} {tier.price_monthly}/mo
                                </span>
                              </Typography>{" "}
                              <Typography component="li" variant="h3" color="textPrimary" style={{ fontSize: "2.8em" }}>
                                {tier.currencySymbol}{" "}
                                {tier.zero_decimal
                                  ? Math.round(tier.price_monthly * (1 - discountPercentage))
                                  : Math.round(tier.price_monthly * (1 - discountPercentage) * 100) / 100}
                                <span className="text-gray-500 text-xl"> /mo</span>
                              </Typography>
                            </React.Fragment>
                          ) : (
                            <div className={"text-black-400 opacity-90 " + pricingFontSize}>
                              {tier.currencySymbol}{" "}
                              {annualToggle
                                ? formatMonthlyPrice(annualToMonthly(tier.price_annual))
                                : formatMonthlyPrice(tier.price_monthly)}
                              <span className="text-gray-500 text-xl">{" /mo"}</span>
                            </div>
                          )}
                        </ul>
                      </div>
                      <ul className="m-2" style={{ paddingBottom: "1em" }}>
                        {tier.plan_name !== "Free" && annualToggle && (
                          <Typography component="li" variant="caption" align="center">
                            6 months free, billed yearly {tier.currencySymbol} {tier.price_annual}.
                          </Typography>
                        )}
                        {tier.plan_name !== "Free" && !annualToggle && (
                          <Typography
                            className="cursor-pointer"
                            component="li"
                            variant="caption"
                            align="center"
                            onClick={() => setAnnualToggle(true)}
                          >
                            Get 6 months free with yearly billing.
                          </Typography>
                        )}
                        {tier.plan_name == "Free" && (
                          <Typography component="li" variant="caption" align="center" className="text-white">
                            free
                          </Typography>
                        )}
                      </ul>
                      <ul style={{ paddingBottom: "1em" }}>
                        {tier.quota.map((line) => (
                          <Typography
                            style={{ fontWeight: 600 }}
                            component="li"
                            variant="subtitle1"
                            align="center"
                            key={line}
                          >
                            {line}
                          </Typography>
                        ))}
                      </ul>
                      <Divider />
                      <ul className="pb-5 pt-5 bg-blue-header">
                        {tier.highlightBox.map((line) => (
                          <Typography
                            className="text-sm break-normal"
                            variant="subtitle1"
                            component="li"
                            align="center"
                            key={line.t}
                          >
                            {line.bold ? (
                              <span style={{ fontWeight: 600, color: "rgb(78, 78, 112)" }}>{line.t}</span>
                            ) : (
                              line.t
                            )}
                          </Typography>
                        ))}
                      </ul>

                      <div className="underline font-bold text-base mt-4 text-center">{tier.descriptionTitle}</div>

                      <ul style={{ paddingTop: "1em" }}>
                        {tier.description.map((line) => (
                          <Typography
                            className="text-sm break-normal"
                            variant="subtitle1"
                            component="li"
                            align="center"
                            key={line.t}
                          >
                            {line.bold ? (
                              <span style={{ fontWeight: 600, color: "rgb(78, 78, 112)" }}>{line.t}</span>
                            ) : (
                              line.t
                            )}
                          </Typography>
                        ))}
                      </ul>
                      <ul style={{ paddingTop: "1em" }}>
                        {tier.notes.map((line) => (
                          <Typography component="li" variant="caption" align="center" key={line}>
                            {line}
                          </Typography>
                        ))}
                      </ul>
                    </div>
                    <CardActions style={{ padding: 12 }}>
                      <button
                        className={
                          "text-white rounded-lg w-full p-3 text-base " +
                          (plan === tier.plan ? "bg-gray-400" : "bg-blue-dark")
                        }
                        disabled={plan === tier.plan ? true : false}
                        fullWidth
                        variant={tier.buttonVariant}
                        color="primary"
                        onClick={() => {
                          if (tier.plan_name === "Free") {
                            //props.history.push("/login?mode=register");
                            window.location.href =
                              config.player + "/login?mode=register" + (queryString ? "&" + queryString : "");
                          } else if (user?.attributes?.plan_subscriptionid) {
                            setConfirmSubscription({
                              url:
                                config.player +
                                "/checkout/subscription?hash=" +
                                tier.hash +
                                (annualToggle ? "&interval=year" : "") +
                                (queryString ? "&" + queryString : ""),
                              tier,
                              annualToggle,
                            });
                            /* window.location.href =
                              config.player +
                              "/checkout/subscription?hash=" +
                              tier.hash +
                              (annualToggle ? "&interval=year" : "") +
                              (queryString ? "&" + queryString : ""); */
                          } else {
                            // if (props.campaign === "secret") dispatch(Actions.addToCart([{ ...tier, coupon: coupon }]));
                            // else dispatch(Actions.addToCart([{ ...tier }]));
                            window.location.href =
                              config.player +
                              "/checkout/subscription?hash=" +
                              tier.hash +
                              (annualToggle ? "&interval=year" : "") +
                              (queryString ? "&" + queryString : "");
                          }
                        }}
                      >
                        {getButtonText(tier)}
                      </button>
                    </CardActions>
                  </Grid>
                </div>
              );
          })}
        </div>
      </Container>
      {/* Footer */}
      {userLoggedIn ? "" /*<ShowCreditsButton userLoggedIn={userLoggedIn} />*/ : ""}
      <Box mt={5}>
        <Copyright />
      </Box>
      {/* End footer */}
    </React.Fragment>
  );
}
/*
function ShowCreditsButton(props) {
  const [dialogOfferCreditsOpen, setDialogOfferCreditsOpen] = useState(false);
  const classes = useStyles();

  if (props.userLoggedIn === true)
    return (
      <React.Fragment>
        <Container maxWidth="md" className={classes.footer}>
          <Typography
            component="h2"
            variant="h2"
            align="center"
            gutterBottom
            style={{
              color: "#36355b",
              fontFamily: "Muli, sans-serif",
              fontSize: "3em",
            }}
          >
            Prefer to pay as you go?
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" component="p">
            <span
              onClick={() => {
                setDialogOfferCreditsOpen(true);
              }}
              style={{ textDecoration: "underline", fontWeight: 600, color: "rgb(78, 78, 112)", cursor: "pointer" }}
            >
              Click here to learn more.
            </span>
          </Typography>
        </Container>
        {dialogOfferCreditsOpen ? (
          <DialogOfferCredits
            purchaseClickSource="menu"
            history={props.history}
            open={dialogOfferCreditsOpen}
            onClose={() => {
              setDialogOfferCreditsOpen(false);
            }}
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  else return null;
}*/
