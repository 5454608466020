import VideoEditor from './videoeditor';

export default class DTCommon {

	data = {
		"MLLandscapeInitialWidth" : this.getWidth,
	}

	constructor(){
		this.VideoEditor = new VideoEditor()
	}



	getWidth(){
		let multiplier = 0.25;
		let w = window.outerWidth;
		let res = w * multiplier;
		// if (w <= 1980){
		// 	res = w * multiplier+0.15;
		// }
		// alert(w);
		if (w <= 1050){
			res = w * (multiplier+0.18);
		}
		
		return res;
	}

	getVar(key){
		if (key in this.data){
			let val = this.data[key]
			if (typeof val === "function"){
				return val();
			}
			return val;
		}
		return null;
		// switch(key){
		// 	case "MLLandscapeInitialWidth":
		// 	return this.data[]

		// }
	}

	getDeviceOrientation(){
		var mql = window.matchMedia("(orientation: portrait)");
		// If there are matches, we're in portrait
		if(mql.matches) {  
			// Portrait orientation
			// console.log("orientation = portrait")
			return "portrait"
		} else {  
			// Landscape orientation
			// console.log("orientation = landscape")
			return "landscape"
		}
	}

	isDevicePortrait(){
		if (this.getDeviceOrientation() === "portrait"){
			return true;
		}
		return false;
	}
}