import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "../actions";

import DTButton from "../common/theme/DTButton";

function MessageDialog(props) {
  const dispatch = useDispatch();

  return (
    <div>
      <Dialog open={props.open ? props.open : false} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <DTButton onClick={props.handleClose} primary>
            Close
          </DTButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MessageDialog;
