export default (state = [], payload) => {
  switch (payload.type) {
    case "ADD_TO_CART":
      return payload.data;
    case "DELETE_CART":
      return [];

    default:
      return state;
  }
};
