export default (state = { loading: false, data: [] }, payload) => {
  switch (payload.type) {
    case "GET_WORK_HISTORY":
      return { loading: true, data: [] };
    case "WORK_HISTORY":
      return { loading: false, data: payload.data };
    case "SET_PROJECT_NAME_SUCCESS":
    case "WORK_HISTORY_UPDATE_STAGE":
      return { loading: false, data: updateItems(state.data, payload) };
    case "UPDATE_PROJECT_ATTRIBUTES":
    case "SET_PROJECT_RESTRICTED_SUCCESS":
    case "SET_PROJECT_PUBLIC_SUCCESS":
    case "SET_PROJECT_PRIVATE_SUCCESS":
      return { loading: state.loading, data: updateAttributes(state.data, payload) };
    default:
      return state;
  }
};

function updateAttributes(state, payload) {
  let found = false;

  let newstate = state.map((item) => {
    if (item.projectid !== payload.data.projectid) {
      return item;
    } else {
      if (payload.data.attributes) {
        if (item.attributes) item.attributes = Object.assign(item.attributes, payload.data.attributes);
        else item.attributes = Object.assign({}, payload.data.attributes);
      }
      return item;
    }
  });

  return newstate;
}

function updateItems(state, payload) {
  let found = false;
  let newstate = state.map((item) => {
    if (item.projectid !== payload.data.projectid) {
      return item;
    } else {
      found = true;
      return Object.assign(item, payload.data);
    }
  });
  if (!found) {
    let newitem = payload.data;
    if (!newitem.projectName) newitem.projectName = "";
    newstate.unshift(newitem);
  }
  return newstate;
}
