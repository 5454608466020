export default (state = false, payload) => {
  // console.log("PAYLOAD---", payload.type)
  switch (payload.type) {
    case "FRONTEND_TRIGGERED_RELOAD":
    case "UPDATE_VIDEO_SCENE_ATTRIBUTES":
      return true;
    case "UPDATE_VIDEO_SCENE_ATTRIBUTES_CLEAR":
      return false;
    default:
      return state;
  }
};
