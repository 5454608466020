import "react-app-polyfill/ie9";
import "core-js/stable";
//import "react-app-polyfill/stable";

//import "./common/modernizr-webp";
// import { install } from '@material-ui/styles';
//import bugsnagClient from "./bugsnag";
import posthog from "posthog-js";

if (process.env.REACT_APP_NODE_ENV === "development")
  posthog.init("phc_8RyFVpZYLHfNljohjaD5BTFwurZ8t3rAUwpwPWGIMMQ", { api_host: "https://eu.posthog.com" });

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
  apiKey: "ab8b604cdbf2b3ba5e8a263ac7d66a4b",
  appVersion: process.env.REACT_APP_BUILD_NUMBER,
  notifyReleaseStages: ["production", "development", "production-staging", "development-staging"],
  releaseStage: process.env.REACT_APP_NODE_ENV,
  consoleBreadcrumbsEnabled: process.env.REACT_APP_NODE_ENV === "production" ? false : false,
  plugins: [new BugsnagPluginReact()],
});

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./main.css";
import "./css/index.css";
import "./css/fonts.css";
import App from "./containers/App";

import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import history from "./libs/history";
import DTCommon from "./common/common";
//import DTGoogleCustomAPI from "./common/googlecustomapi";
import MLHelper from "./components/medialibrary-helper";

//import ReactGA from "react-ga";
import withTracker from "./withTracker";
import Fingerprint2 from "fingerprintjs2";

import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";

const queryClient = new QueryClient();
//import TagManager from "react-gtm-module";

//import LogRocket from "logrocket";

window.DTCommon = new DTCommon();
window.DTAppCommon = window.DTCommon;
window.DTmedialibrary_height = 70;

//require("dotenv").config({ silent: true });
//var config = require("./config");
/*
if ( process.env.REACT_APP_NODE_ENV === "development" || process.env.REACT_APP_NODE_ENV === "production")
  LogRocket.init(config.logrocket, { release: process.env.REACT_APP_BUILD_NUMBER });
*/
/*
TagManager.initialize({
  gtmId: config.newGoogleAnalyticsId,
  dataLayerName: "TagDataLayer",
}); */
//ReactGA.initialize(config.googleAnalyticsId);
/*eslint-disable*/
window.ReactDOM = ReactDOM;
window.MLHelper = MLHelper;
//window.DTGoogleCustomAPI = DTGoogleCustomAPI();
/*eslint-enable*/

var guid = function guid() {
  const options = {};
  return Fingerprint2.getPromise(options).then((components) => {
    const values = components.map((value) => value);
    return Fingerprint2.x64hash128(values.join(""), 31);
  });
};

(async function () {
  const fpid = await guid();
  window.fpid = fpid;
  //console.log("FPID: " + fpid); // Now you can use it
  //document.cookie = `fpid=${encodeURIComponent(fpid)}`;
})();

// record ga4 client id to window
gtag("get", process.env.REACT_APP_GA4, "client_id", (clientId) => {
  window.ga4clientid = clientId.toString();
  console.log("google client_id", window.ga4clientid);
});

console.log("environment: " + process.env.REACT_APP_NODE_ENV);
console.log("build: " + process.env.REACT_APP_BUILD_NUMBER);

/*  <Router basename={ process.env.REACT_APP_NODE_ENV === "development" && process.env.NODE_ENV === "production" ? '/beta': '/'}> */
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
ReactDOM.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Router>
            <Route component={withTracker(App, { history: history })} />
          </Router>
        </ConnectedRouter>
      </Provider>
    </QueryClientProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);

//<Route component={App} history={history} />
