export default (state = false, payload) => {
  let out = false;
  // console.log("PAYLOAD---", payload.type)
  switch (payload.type) {
    case "LOGIN":
    case "GENERATE_LOGIN_TOKEN":
    case "LOGIN_WITH_TOKEN":
      out = true;
      break;
    case "LOGIN_SUCCESS":
    case "LOGIN_FAILURE":
    case "LOGIN_WITH_TOKEN_FAILURE":
    case "GENERATE_LOGIN_TOKEN_FAILURE":
    case "LOGOUT":
      out = false;
      break;
    default:
      return state;
  }
  return out;
};
