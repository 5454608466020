import merge from "deepmerge";
import config from "../config";

export default (state = {}, payload) => {
  switch (payload.type) {
    case "LOGGING_IN":
      return {
        ...state,
        logging_in: true,
      };
    case "GENERATE_LOGIN_TOKEN_SUCCESS":
      // console.log('dt_login_token', payload);
      try {
        localStorage.setItem("dt_login_token", payload.data.loginToken);
        localStorage.setItem("dt_user_has_login", true);
      } catch (err) {
        console.log("can not access LocalStorage", err);
      }
      return {
        ...state,
        loginToken: payload.data.loginToken,
      };
    case "LOGIN_WITH_ACCESS_TOKEN_FAILURE":
      return {
        logging_in: false,
        access_token_login_failure: true,
      };
    case "LOGIN_WITH_TOKEN_FAILURE":
      return {
        logging_in: false,
        token_login_failure: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...payload.user,
        logging_in: false,
        token_login_failure: null,
        access_token_login_failure: null,
        logout: false,
      };
      // return payload.user;
      break;
    case "USER_SIGNUP_WINDOW_CLOSED":
      return {
        ...state,
        close_signup_window: false,
      };
    case "CREATE_SELLER_SUCCEEDED":
      return {
        ...state,
        seller_url: payload.data,
      };
    case "UPDATE_USER_INFO_SUCCESS":
      return merge(state, payload.user);

    case "UPDATE_USER":
      return merge(state, payload.user);

    case "SET_PREFERRED_FACE_SUCCEEDED":
      if (payload.data.userid === state.id) {
        let newstate = Object.assign(state);
        newstate.attributes = Object.assign(state.attributes, { preferredFace: payload.data.preferredFace });
        return newstate;
      } else return state;

    case "GET_AVATAR_LIST_SUCCEEDED":
      return { ...state, avatarlist: payload.data };
    case "GET_ALL_VOICES_SUCCESS":
      return { ...state, voiceslist: payload.data };
    case "GET_AVATAR_SYNTHESIA_LIST_SUCCEEDED":
      return { ...state, avatarSynthesiaList: payload.data };
    case "GET_LANGUAGE_LIST_SUCCEEDED":
      return { ...state, languageList: payload.data };
    case "GET_AVATAR_AUTOMATEVIDEO_LIST_SUCCEEDED":
      return { ...state, avatarAutomateVideoList: payload.data };
    case "GET_SYNTHESIA_VOICES_SUCCESS":
      return { ...state, synthesiavoices: payload.data };
    case "GET_GOOGLE_VOICES_SUCCESS":
      return { ...state, googlevoices: payload.data };
    case "SYNTHESIA_TERMS_CONDITIONS_SUCCESS":
      return { ...state, synthesia_has_readTC: true };

    case "USER_CREATE_NEW_APIKEY_SUCCESS":
      return { ...state, apikey: payload.data.apikey };

      break;
    case "LOGIN_REDIRECTED":
      return {
        ...state,
        redirected: true,
      };
      break;
    case "AUTHENTICATE_FAILURE":
    case "LOGOUT":
      try {
        localStorage.removeItem("DTh5pToken");
        localStorage.removeItem("dt_login_token");
        localStorage.removeItem("dt_access_token");
      } catch (err) {
        console.log("can not access localStorage", err);
      }
      return { logout: true };
      break;
    default:
      return state;
  }
};
