export default (state = {}, payload) => {
  switch (payload.type) {
    case "GET_PRICELIST_SUCCESS":
      state.pricelist = payload.data;
      return state;

    default:
      return state;
  }
};
