export default (state = null, payload) => {
  switch (payload.type) {
    case "DOWNLOAD_TO_MEDIALIBRARY_FAILURE":
      return { msg: "Unable to load file in the Media library" };
    // case 'MEDIA_LIBRARY_DRAWER':
    //     return payload.data;
    case "EDIT_NOTENOUGHCREDIT_FAILED":
      return { msg: "edit failed, you do not have enough credit" };
    case "CLEAR_GLOBAL_ERROR":
      return null;
    default:
      return state;
  }
};
