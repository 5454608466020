//import store from '../store'

//var SocketIOFileUpload = require("socketio-file-upload");

/*
Object.defineProperty(exports, "__esModule", {
  value: true
}); */

export default function foo(socket) {
  var channelName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "action";

  return function (store) {
    socket.on(channelName, store.dispatch);

    return function (next) {
      return function (action) {
        console.log(action);
        if (
          action &&
          action.data &&
          action.data.hasOwnProperty("messageId") &&
          action.data.hasOwnProperty("popReceipt")
        ) {
          //console.log("found messageid and popreceipt");
          socket.emit("confirm", {
            type: "CONFIRM_MESSAGE",
            data: { messageId: action.data.messageId, popReceipt: action.data.popReceipt },
            meta: { confirm: true },
          });
          //return next(action);
        }

        if (action.meta && action.meta.remote) {
          var accessToken = store.getState().user.accessToken;
          if (accessToken) action.accessToken = accessToken;
          else {
            var token = store.getState().user.token;
            if (token) {
              action.token = token;
            }
            // else redirect to login page
          }

          //console.log("socket = channel = ", channelName, action);
          socket.emit(channelName, action);
          return next(action);
        } else {
          return next(action);
        }
      };
    };
  };
}
