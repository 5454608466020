import store from "../store";

export function isFeatureEnabled(feature) {
  try {
    const features = store.getState().config.features;
    let userFeatures = null;
    let user = store.getState().user;

    let result = false;
    if (
      user &&
      user.attributes.features &&
      user.attributes.features.hasOwnProperty(feature) &&
      user.attributes.features[feature] === true
    )
      result = true;

    /*if (user && user.attributes) userFeatures = user.attributes.features;
    
    if (feature && features.hasOwnProperty(feature)) {
      if (features[feature].hasOwnProperty("default")) result = features[feature].default;
      if (!(features[feature].hasOwnProperty("lockedForAll") && features[feature].lockedForAll === true)) {
        if (userFeatures && userFeatures.hasOwnProperty(feature)) result = userFeatures[feature];
      }
    }*/
    return result;
  } catch (err) {
    console.log(err);
    return false;
  }
}
