import merge from "deepmerge";
import config from "../config";

export default (state = {}, payload) => {
  switch (payload.type) {
    case "GET_FACE_LIST_SUCCEEDED":
      return payload.data;
      break;
    default:
      return state;
  }
};
