import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import history from "./libs/history";
import socketIO from "socket.io-client";
import socketIoMiddleware from "./libs/redux-socket.io-middleware";
import { googleAnalytics } from "./libs/reactGAmiddlewares";
import { routerMiddleware } from "connected-react-router";
//import LogRocket from "logrocket";
import rootReducer from "./reducers";
//import logger from 'redux-logger';

var config = require("./config");
const io = socketIO.connect(config.socketIo);
io.on("connect", function () {
  console.log("SOCKETID", io.id);
  window.clientid = io.id;
});

let debugProduction = false;

const middlewares = [];
const actionSanitizer = (action) =>
  action.type === "MEDIALIBRARY" && action.data
    ? { ...action, data: [{ removed_too_long_data_from_devtools: "" }] }
    : action;

if (process.env.REACT_APP_NODE_ENV !== "production" || debugProduction) {
  const { logger } = require("redux-logger");
  middlewares.push(logger);
}
middlewares.push(socketIoMiddleware(io));
middlewares.push(thunk);
middlewares.push(routerMiddleware(history));
middlewares.push(googleAnalytics);
//middlewares.push(LogRocket.reduxMiddleware());
let composeEnhancer = compose;
if (
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() &&
  (process.env.REACT_APP_NODE_ENV !== "production" || debugProduction)
)
  composeEnhancer =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // add sanitizers here as devtools options
      // see https://github.com/zalmoxisus/redux-devtools-extension/tree/94f7e53800f4665bddc9b7438c5cc75cfb4547cc#12-advanced-store-setup
      // section 1.2

      actionSanitizer,
      stateSanitizer: (state) => (state.data ? { ...state, data: "<<LONG_BLOB>>" } : state),
    }) || compose;
const store = createStore(
  rootReducer(history),
  composeEnhancer(
    applyMiddleware(...middlewares)

    //autoRehydrate()
  )
);

export default store;
