export default (state = [], payload) => {
  switch (payload.type) {
    case "ANALYTICS_ADD_TO_QUEUE_SUCCESS":
      let newstate = state;
      newstate.push(payload.data.analyticsid);
      return newstate;
    default:
      return state;
  }
};
