export default (state = [], payload) => {
  switch (payload.type) {
    case "GET_PROJECT_SESSIONS_SUCCESS":
      let found = false;
      for (let v of state) if (v.projectid === payload.data.projectid) found = true;
      if (found) return updateViews(state, payload);
      else {
        state.push(payload.data);
        return state;
      }
      break;
    default:
      return state;
  }
};

function updateViews(state, payload) {
  return state.map((view) => {
    if (view.projectid !== payload.data.projectid) {
      return view;
    }
    return {
      ...view,
      sessions: payload.data.sessions,
    };
  });
}
