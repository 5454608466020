var findInArr = (data1, data2) => {
  for (var i = data1.length - 1; i >= 0; i--) {
    let found = false;
    for (var j = data2.length - 1; j >= 0; j--) {
      if (data1[i].id === data2[j].id) {
        found = true;
      }
    }
    if (!found) {
      return data1[i];
    }
  }
};

export default (state = { adding: false, newplc: null }, payload) => {
  switch (payload.type) {
    case "ADDING_PLACEHOLDER":
      return {
        sceneid: payload.data.addData.sceneid,
        oldplaceholders: payload.data.placeholders,
        adding: true,
      };
      break;
    case "FRONTEND_ADDED_MEDIA_TO_PLACEHOLDER":
      return {
        newplc: null,
        adding: false,
      };
      break;
    /* case "UPDATE_VIDEO":
      return {
        adding: false
      };
      break;
      // if (state.adding){
      // 	var newplc = null;
      // 	for (var i = payload.data.scenes.length - 1; i >= 0; i--) {
      // 		// alert(payload.data.scenes[i].aeid + "==" + state.sceneid)
      // 		if (payload.data.scenes[i].sceneid === state.sceneid){
      // 			// alert("Adding")
      // 			var newplaceholders = payload.data.scenes[i].placeholders
      // 			newplc = findInArr(newplaceholders, state.oldplaceholders)
      // 		}
      // 	}
      // 	return {
      // 		newplc: newplc,
      // 		adding:true,
      //           }
      // }

      break; */
    default:
      return state;
  }
  return state;
};
