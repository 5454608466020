import merge from "deepmerge";
import config from "../config";

export default (state = {}, payload) => {
  switch (payload.type) {
    case "SELL_COLLECTION_SUCCEEDED":
      return payload.data;
      break;
    case "CREATE_PRICING_SUCCEEDED":
      return payload.data;
      break;
    case "DELETE_PRODUCT_SUCCEEDED":
      return payload.data;
      break;
    case "DELETE_PRICE_SUCCEEDED":
      return payload.data;
      break;
    case "UPDATE_COLLECTION_PRODUCTSANDPRICES_SUCCEEDED":
      return payload.data;
      break;
    default:
      return state;
  }
};
