export default(state=null, payload) => {
    switch(payload.type) {
        case 'CREATE_H5P_SESSION_SUCCESS':
            // let p = Object.assign(state, payload.data);
            if (payload.data.h5ptoken)
            	return payload.data.h5ptoken; 
            else {
            	return null;
            }
            break;
        default:
            return state;
    }
}