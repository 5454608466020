export default (state = {}, payload) => {
  switch (payload.type) {
    case "SUBSCRIPTIONS_ADD_PENDING_SUCCESS":
      return { payment_id: payload.data.payment_id };
    case "SUBSCRIPTIONS_UPDATE_SUCCESS":
      return { updateSuccess: true };
    default:
      return state;
  }
};
