export default (state = [], payload) => {
  switch (payload.type) {
    case "EDIT_PLACEHOLDER_AVATAR_PRE":
      let newState = [{ ...payload.data }];
      return newState;
    /*case 'UPDATE_VIDEO':
            if (state.length > 0){
                let newState = [...state];
                newState.pop();
                return newState;
            }else{
                return state
            } */
    default:
      return state;
  }
};
