export default(state={}, payload) => {
	let out = {};
    switch(payload.type) {
        case 'UPDATE_WINDOW_DIMENSIONS':
        	out = payload.data;
            break;
        default:
            return state;
    }
    return out; 
}